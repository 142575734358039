import React, { useEffect, useState } from 'react'
import Countries from 'countries'
import { Select } from 'foundations-library/components'
import { SelectType, SelectOptionType } from 'foundations-library/types'

interface PropsInterface extends SelectType {
    type: 'alpha' | 'numeric' | 'alpha3' | 'alpha2' | 'name';
    availableCountries?: string[];
    additionalOptions?: SelectOptionType[];
    'data-testid'?: string;
}

/**
 *
 */
function CountrySelector ({
    value = 'US',
    type = 'alpha2',
    additionalOptions,
    availableCountries,
    onChange,
    'data-testid': dataTestId = 'country-selector',
    ...props
}: PropsInterface): JSX.Element {
    const [options, setOptions] = useState([])

    useEffect(() => {
        let countries = JSON.parse(JSON.stringify(Countries))
        if (Array.isArray(availableCountries)) {
            countries = countries.filter((x) => availableCountries.includes(x.alpha2))
        }
        countries = countries.map((x) => ({ text: x.name, value: x[type] }))

        if (Array.isArray(additionalOptions) && additionalOptions.length) {
            additionalOptions.map((x) => countries.unshift(x))
        }
        setOptions(countries)
    }, [])

    if (type && options && options.length) {
        return <Select
            {...props}
            value={value}
            options={options}
            name="coutry-selector"
            className="country-selector"
            data-testid={dataTestId}
        />
    }

    return <></>
}

export default CountrySelector
