import { ColorsType } from 'interfaces'
import { displays, aligns } from './helpers'

const genericStyles = (colors: ColorsType) => {
    const classes = {
        'text-initial': {
            color: 'initial'
        },
        'text-inherit': {
            color: 'inherit'
        }
    }
    const variants = {
        text: 'color'
        // bg: 'backgroundColor',
        // border: 'borderColor'
    }
    for (const [prefix, prop] of Object.entries(variants)) {
        for (const color of Object.keys(colors)) {
            classes[`${prefix}-${color}`] = { [prop]: colors[color].base }
            for (const shade of Object.keys(colors[color])) {
                classes[`${prefix}-${color}-${shade}`] = { color: colors[color][shade] }
            }
        }
    }
    for (const d of displays) {
        classes[`display-${d}`] = { display: d }
    }
    for (const a of aligns) {
        classes[`text-align-${a}`] = { textAlign: a }
    }
    return classes
}

/**
 *
 */
const style = (t) => {
    const { colors, typography } = t['foundations-library']
    const generic = genericStyles(colors)
    return {
        ...typography,
        ...generic
    }
}

export default style
