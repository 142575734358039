import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import style from './style'
import Icon from '../Icon/Icon'
import Label from '../Label/Label'
import VerbalColorType from '../../helpers/types/VerbalColor.interface'
import CommonInput from '../CommonInput/CommonInput'
import CommonInputType from '../../helpers/types/CommonInput.interface'

const useStyles = makeStyles(style)

interface PropsInterface extends React.ComponentProps<'checkbox'>, CommonInputType {
    label?: string;
    color?: VerbalColorType;
    iconRight?: boolean;
}

/**
 *
 */
const Checkbox = ({
    disabled,
    label,
    children,
    color = 'primary',
    iconRight = false,
    onChange,
    checked,
    value = true,
    'data-testid': dataTestId = 'checkbox',
    ...props
}: PropsInterface): JSX.Element => {
    const styles = useStyles()
    const [isChecked, setIsChecked] = useState(checked)

    const iconWrapperClasses = [
        'icon-wrapper',
        isChecked && `${color}`,
        (label || children) && (iconRight ? 'icon-right' : 'icon-left'),
        isChecked && 'active',
        disabled && 'disabled'
    ].filter((x) => x).map((x) => `checkbox--${x}`).join(' ')

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(!isChecked)

        if (onChange) onChange(e)
    }

    return (
        <CommonInput
            {...props}
            value={isChecked}
            clearable={false}
            data-testid={dataTestId}
        >
            <div className={styles.checkbox}>
                <Label disabled={disabled} data-testid={`${dataTestId}-label`}>
                    <input type="checkbox" checked={ isChecked } onChange={handleChange} disabled={disabled} data-testid={dataTestId} value={value}/>
                    {iconRight && (label || children)}
                    <span className={iconWrapperClasses}>
                        {isChecked && <Icon className="checkbox--icon" name='check' size={16} color='white'/>}
                    </span>
                    { !iconRight && (label || children) }
                </Label>
            </div>
        </CommonInput>
    )
}

/**
 *
 */
export type CheckboxType = PropsInterface

export default Checkbox
