/* eslint-disable @typescript-eslint/naming-convention */

import React, { useEffect, useState, useMemo } from 'react'
import CurrentBalance from '../components/CurrentBalance.tsx'
import PreviusStatement from '../components/PreviusStatement.tsx'
import PaymentMethodCard from '../components/PaymentMethodCard.tsx'
// import PlanDetails from '../components/PlanDetails.tsx'
import { makeStyles } from '@material-ui/core'
import API from '../../account/src/util/api_v5.js'
import Loader from '../components/Loader'
import PaymentMethod from 'console/src/models/PaymentMethod'
import { PhoenixSessionContext } from 'providers'

const style = () => ({
    container: {
        width: '900px',
        display: 'flex',
        margin: 'auto',
        '& .column': {
            width: '50%'
        }
    }
})
const useStyles = makeStyles(style)

interface PropsInterface {
    routeProps?: any
    setActiveTab(v): any;
}

type Account = {
    [key: string]: any
}

/**
 *
 */
const App = ({
    routeProps,
    setActiveTab
}: PropsInterface): JSX.Element => {
    const sessionContext = React.useContext(PhoenixSessionContext)
    const pMethod = useMemo(() => new PaymentMethod(sessionContext.session, null), [])

    const classes = useStyles()
    const [account, setAccount] = useState<Account | null>(null)
    const [loading, setLoading] = useState(false)
    const [primaryCard, setPrimaryCard] = useState({})
    const [allCards, setAllCards] = useState(null)

    const fetchData = async () => {
        setLoading(true)
        try {
            const response = await API.getAccount()
            const modifiedResponse = Object.fromEntries(
                Object.entries(response).map(([key, value]) => {
                    if (typeof value === 'string' && value.includes('CR')) {
                        return [key, value.replace('CR', ' CR')]
                    }
                    return [key, value]
                })
            )
            setAccount(modifiedResponse)
            await pMethod.loadItems()
            const primary = pMethod.items.find((x) => x.status === 'primary')
            if (primary) setPrimaryCard({ ...primary })
            setAllCards([...pMethod.items])
        } catch (error) {
            console.error('Error fetching data:', error)
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchData()
    }, [])
    return (
        <>
            {loading && <Loader />}
            {!loading && <div data-testid="index-container" className={classes.container}>
                {account && (<>
                    <div data-testid="index-column-1" className={'column'} style={{ paddingRight: '10px' }}>
                        <CurrentBalance account={account} primaryCard={primaryCard} allCards={allCards} fetchData={fetchData}/>
                        <PreviusStatement routeProps={routeProps} setActiveTab={setActiveTab} account={account} />
                    </div>
                    <div data-testid="index-column-2" className={'column'} style={{ paddingLeft: '10px' }}>
                        <PaymentMethodCard primaryCard={primaryCard} />
                        {/* <PlanDetails /> */}
                    </div>
                </>)}
            </div>}
        </>
    )
}

export default App
