import { getVerbalColors } from 'helpers'

/**
 *
 */
const style = (t) => {
    const { colors } = t['foundations-library']
    const verbalColors = getVerbalColors(colors)
    const coloring = {
        '& .switch--disabled > .switch--slider': {
            backgroundColor: `${verbalColors['neutral-200']} !important`
        }
    }

    for (const [verbal, color] of Object.entries(verbalColors)) {
        coloring[`& .switch--active.switch--${verbal} .switch--slider`] = {
            backgroundColor: color
        }
    }
    return {
        switch: {
            position: 'relative',
            verticalAlign: 'middle',
            cursor: 'pointer',
            '& .switch--input-wrapper': {
                width: '30px',
                height: '16px'
            },
            '& .switch--input': {
                height: '0',
                width: '0',
                opacity: '0'
            },
            '& .switch--slider': {
                position: 'absolute',
                cursor: 'pointer',
                width: '30px',
                height: '16px',
                margin: 'auto 0',
                top: '0',
                left: '0',
                right: '0',
                bottom: '0',
                backgroundColor: colors.neutral[500],
                '-webkit-transition': '.4s',
                borderRadius: '24px',
                transition: '.4s',
                '&:before': {
                    position: 'absolute',
                    content: '""',
                    height: '10px',
                    width: '10px',
                    left: '3px',
                    bottom: '3px',
                    backgroundColor: 'white',
                    '-webkit-transition': '.4s',
                    transition: '.4s',
                    borderRadius: '50%'
                }
            },
            '& .switch--active .switch--slider:before': {
                '-webkit-transform': 'translateX(14px)',
                '-ms-transform': 'translateX(14px)',
                transform: 'translateX(14px)'
            },
            '& .switch--icon-right': {
                marginLeft: '8px',
                '& .switch--slider': {
                    left: 'unset'
                }
            },
            '& .switch--icon-left': {
                marginRight: '8px'
            },
            '& .switch--icon': {
                margin: '0 auto'
            },
            ...coloring
        }
    }
}

export default style
