import React from 'react'
import { Skeleton } from 'foundations-library/components'

/**
 *
 */
const PMLoader = (): JSX.Element => {
    return <div data-testid='payment-methods-loader'>
        {[1, 2, 3].map((x) => <Skeleton variant='rect' height='78px' width='100%' style={{ marginBottom: '20px' }} key={`pm-loader-${x}`}/>)}
    </div>
}

export default PMLoader
