import React, { useState, useEffect, useContext } from 'react'
import RemoteConfigValue from 'remote-config-value'
import { makeStyles } from '@material-ui/core/styles'
import style from './style'
import { ThemeContext } from 'providers'
import TypographyType from './Typography.interface'
import { getVerbalColors } from 'helpers'

const useStyles = makeStyles(style)

const getValue = (rc, children) => {
    if (rc) {
        return (<RemoteConfigValue valueId={rc}/>)
    }
    if (children) {
        return children
    }

    console.warn('Typography component has no children or remote config ID.')
}

/**
 *
 */
const Typography = ({
    variant = 'body1',
    remoteConfigID,
    tag = 'div',
    children = '',
    className,
    display,
    align,
    color = 'neutral-900',
    style = {},
    onClick = () => undefined,
    'data-testid': dataTestId = 'typography'
}: TypographyType): JSX.Element => {
    const Tag = tag
    const [classes, setClasses] = useState([])
    const styles = useStyles()
    const themeContext = useContext(ThemeContext)

    useEffect(() => {
        const exampleVerbalColors = getVerbalColors(themeContext['foundations-library'].colors)

        const colors = ['initial', 'inherit', ...Object.keys(exampleVerbalColors)]
        const classesArr = [variant]
        if (!colors.includes(color)) {
            console.error('Invalid color')
        } else {
            classesArr.push(`text-${color}`)
        }
        if (display) classesArr.push(`display-${display}`)
        if (align) classesArr.push(`text-align-${align}`)

        setClasses([...classesArr])
    }, [color, display, align, variant])

    return (
        <Tag
            onClick={onClick}
            className={`${className} ${classes.map((x) => styles[x]).join(' ')}`}
            data-testid={dataTestId}
            style={style}
        >{getValue(remoteConfigID, children)}</Tag>)
}

export default Typography
