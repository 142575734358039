import React, { useEffect, useState, useMemo } from 'react'
import PaymentMethod from 'console/src/models/PaymentMethod'
import { PhoenixSessionContext } from 'providers'
import { Alert } from 'foundations-library/components'
import TabsSubmenu from '../../elements/TabsSubmenu'
import ItemsList from '../../elements/List'
import { ConfirmationDialog } from 'functional-foundations'
import { makeStyles } from '@material-ui/core'
import style from './style'
import PMLoader from '../../elements/PMLoader'

const useStyle = makeStyles(style)

interface PropsInterface {
    onClickAddPaymentMethod(): void;
    className?: string;
}

/**
 *
 */
const index = ({ onClickAddPaymentMethod, className }: PropsInterface): JSX.Element => {
    const sessionContext = React.useContext(PhoenixSessionContext)
    const pMethod = useMemo(() => new PaymentMethod(sessionContext.session, null), [])

    const styles = useStyle(style)

    const [items, setItems] = useState([])
    const [loading, setLoading] = useState(false)
    const [primaryCCIid, setPrimaryCCIid] = useState(null)
    const [newPrimaryMethodId, setNewPrimaryMethodId] = useState(null)
    const [deletePaymentMethodId, setDeletePaymentMethodId] = useState(null)
    const [alert, setAlert] = useState(null)
    const [disableForm, setDisableForm] = useState(false)

    const onClickSetPrimary = async (id) => {
        if (primaryCCIid === id) {
            return true
        }

        setNewPrimaryMethodId(id)
    }

    useEffect(() => {
        setLoading(true)
        pMethod.loadItems().then((res) => {
            const primary = pMethod.items.find((x) => x.status === 'primary')
            setItems([...res])
            if (primary) setPrimaryCCIid(primary.id)
            setLoading(false)
        })
    }, [])

    const closeNewPrimaryMethodIdModal = () => setNewPrimaryMethodId(null)
    const closeDeletePaymentMethodId = () => setDeletePaymentMethodId(null)

    const saveNewPrimaryMethodId = async () => {
        setDisableForm(true)
        const id = newPrimaryMethodId
        setNewPrimaryMethodId(null)

        if (primaryCCIid === id) return true

        const originalCcId = primaryCCIid
        setPrimaryCCIid(id)

        await pMethod.set_status(id, 'primary')
        if (pMethod.alert && pMethod.alert.level === 'error') {
            setPrimaryCCIid(originalCcId)
        }
        setAlert(pMethod.alert)
        setDisableForm(false)
    }

    const deletePaymentMethod = async () => {
        setDisableForm(true)
        const id = deletePaymentMethodId
        setDeletePaymentMethodId(null)
        await pMethod.delete_item(id)
        if (pMethod.alert && pMethod.alert.level === 'success') setItems([...items.filter((x) => x.id !== id)])
        setAlert(pMethod.alert)
        setDisableForm(false)
        setDeletePaymentMethodId(null)
    }

    return (
        <div className={`${styles.list} ${className}`} data-testid='payment-methods-list'>
            {loading && <PMLoader marginTop={60}/>}
            {!loading && <>
                {/* <Alert level='error' data-testid='payment-methods-list-autopay-alert'>
                    You are currently using Autopay. Please update your payment information as your previous payment has failed.
                </Alert> */}
                {
                    alert &&
                    <Alert
                        level={alert.level}
                        onClose={() => setAlert(null)}
                        autoclose
                        marginY={20}
                        data-testid='payment-methods-list-alert'
                    >{alert.message}</Alert>}
                {!loading && (
                    <div className='payment-methods--content'>
                        <TabsSubmenu value='list' onChange={(v) => v === 'create' && onClickAddPaymentMethod()} data-testid='payment-methods-list-tabs'/>
                        {
                            items &&
                            <ItemsList
                                items={items}
                                onClickSetPrimary={onClickSetPrimary}
                                onClickRemove={setDeletePaymentMethodId}
                                disabled={disableForm}
                                withOptions
                                data-testid='payment-methods-items-list'
                            />
                        }
                    </div>
                )}
            </>}
            <ConfirmationDialog
                isOpen = {!!newPrimaryMethodId}
                onClose = {closeNewPrimaryMethodIdModal}
                onConfirm={saveNewPrimaryMethodId}
            >
                You&apos;re changing your primary payment method. Would you like to continue?
            </ConfirmationDialog>
            <ConfirmationDialog
                isOpen = {!!deletePaymentMethodId}
                onClose = {closeDeletePaymentMethodId}
                onConfirm={deletePaymentMethod}
                confirmText='Delete'
                destructive
            >
                Are you sure you&apos;d like to delete this card we have on file?
            </ConfirmationDialog>
        </div>
    )
}

export default index
