import React from 'react'
import { ContentLoader } from 'foundations-library/components'
import { makeStyles } from '@material-ui/core'
import { ContentFactoryInterface } from './ContentLoaderFactory'

const jss = () => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        '& .loader-button:not(:first-child)': {
            marginLeft: '20px'
        }
    }
})

const useStyles = makeStyles(jss)

const buttons = (n, dataTestId) => {
    const widthHeight = {
        width: 110,
        height: 36
    }
    const els = [
        <ContentLoader
            className='loader-button'
            {...widthHeight}
            key='button-rect-0'
            data-testid={`${dataTestId}-svg-0`}
        >
            <rect x="0" y="0" rx="5" ry="5" {...widthHeight}/>
        </ContentLoader>]
    for (let i = 1; i < n; i++) {
        els.push(<ContentLoader
            className='loader-button'
            {...widthHeight}
            key={`button-svg-${i}`}
            data-testid={`${dataTestId}-svg-${i}`}
        ><rect x="0" y="0" rx="5" ry="5" {...widthHeight}/></ContentLoader>)
    }

    return els
}
interface PropsInterface extends ContentFactoryInterface {
    pull?: 'right' | 'left'
}

/**
 *
 */
const Button = ({
    times = 1,
    pull,
    'data-testid': dataTestId = 'button-content-loader',
    ...props
}: PropsInterface): JSX.Element => {
    const styles = useStyles()
    let inlineStyle = {}
    if (pull) {
        inlineStyle.justifyContent = pull
    }
    if (times === 1 && !pull) {
        inlineStyle.display = 'block'
    }
    if (props.style) {
        inlineStyle = {
            ...props.style,
            ...inlineStyle
        }
    }
    return <div className={styles.container} style={ inlineStyle } data-testid={dataTestId}>
        {buttons(times, dataTestId)}
    </div>
}

export default Button
