import React, { useEffect, useState } from 'react'
import { Typography } from 'foundations-library/components'
import ListItem from './ListItem'
import { CreditCardType } from 'interfaces'
import { makeStyles } from '@material-ui/core'

const style = () => ({
    list: {}
})
const useStyles = makeStyles(style)

interface PropsInterface {
    items: Array<CreditCardType>;
    disabled: boolean;
    onClickSetPrimary?(id): any;
    onClickRemove?(id): any;
    onClickEdit?(id): any;
    withOptions?: boolean;
    onChange?(id): any;
    value?: number;
    'data-testid'?: string;
}

/**
 *
 */
const index = ({
    items,
    disabled,
    onClickSetPrimary,
    onClickRemove,
    // onClickEdit,
    withOptions = false,
    onChange,
    value,
    'data-testid': dataTestId = 'payment-methods-items-list'
}: PropsInterface): JSX.Element => {
    const styles = useStyles()
    const [clicked, setClicked] = useState(value)
    useEffect(() => onChange && onChange(clicked), [clicked])
    useEffect(() => setClicked(value), [value])

    return (
        <div className={styles.list} data-testid={dataTestId}>

            {
                (!items || (items && !items.length)) &&
                    <Typography
                        variant="body2"
                        data-testid="no-payment-methods"
                    >You currently have no credit card or debit card on file.</Typography>
            }
            {items && items.length > 0 && items.map((item, i) => {
                const options = []
                if (withOptions) {
                    // options.push({ name: 'Edit', cb: (id) => onClickEdit && onClickEdit(id), value: 'edit' })
                    if (item.status !== 'primary' && items.length > 1) {
                        options.push({ text: 'Delete', cb: (id) => onClickRemove && onClickRemove(id), value: 'delete' })
                    }
                    if (item.status !== 'primary') {
                        options.push({ text: 'Set as primary', cb: (id) => onClickSetPrimary && onClickSetPrimary(id), value: 'set-as-primary' })
                    }
                }
                return <ListItem
                    item={item}
                    disabled={disabled}
                    onClick={(id) => setClicked(id)}
                    className={clicked === item.id && 'payment-method--selected'}
                    options={options}
                    data-testid={`${dataTestId}-item-${item.id}`}
                    key={`${dataTestId}-${i}`}
                />
            })}
        </div>
    )
}

export default index
