/**
 *
 */
const style = (t) => {
    const { colors, typography } = t['foundations-library']
    return {
        tab: {
            height: '44px',
            borderRadius: '30px',
            padding: '10.5px 20px',
            backgroundColor: colors.primary[50],
            color: colors.primary[500],
            width: '100%',
            ...typography.subtitle1,
            '&:hover': { backgroundColor: 'rgba(170, 219, 160, 0.7)' },
            '&.active--tab': {
                backgroundColor: colors.secondary[500],
                color: 'white'
            },
            '& .tab--icon': {
                marginRight: '6px'
            },
            '& .tab--icon-right': {
                marginLeft: '6px',
                marginRight: '0'
            },
            '& .tab--notification': {
                marginLeft: '6px'
            }
        }
    }
}

export default style
