<template>
    <div class="route-configuration">
      <div>
        <template v-if="route.hasOwnProperty('name')">
          <FormInput :label="l.t('app.name', 'Name')" class="my-5">
            <w-text-field v-model="route.name" :rules="[$validation.required()]" :not-clearable="readonly" hide-details='auto' data-test-id="route-conf-name-input" />
          </FormInput>
        </template>
        <draggable
          v-model="route.rules"
          handle=".dragging-element"
          ghost-class="dropzone"
          :disabled="readonly"
          :forceFallback="true"
          @start="blur_all_selectors"
          @update="$global_emitter.$emit('form_input_changed')"
        >
        <div
          v-for="(rule, i) in route.rules"
          class="route-rule mb-10"
          :key="`rule-${rule.index}`"
          :data-test-id="`route-conf-rule-main-${i}-${rules_key}`"
        >
          <div class="route-rule-configuration">
                  <RouteRuleFilter
                    :_filter="rule['filter']"
                    :_extension="_extension ? _extension : route.extension"
                    :route="route"
                    :readonly="readonly"
                    :rule_index="i"
                    @updated="on_filter_update(i, $event)"
                    @closed="remove_rule(i)"
                    :data-test-id="`route-conf-rule-filter-${i}`"
                  >
                  </RouteRuleFilter>
              </div>
              <div class="mt-8" :key="actions_key">
                <div class="w-subtitle-2 info--text text--darken-1">
                  2. {{ l.t('routes.select-handled', 'Select how calls should be handled') }}
                </div>
                <RouteRuleActions
                  :rule="rule"
                  :rule_index="i"
                  :readonly="readonly"
                  :extension="extension"
                  :route="route"
                  @dragging="blur_all_selectors"
                  @action_changed="(e, action_index) => action_action_changed(e, i, action_index)"
                  @remove_action="(action_index) => remove_action(i, action_index)"
                  />
              </div>
              <div
                v-if="rule['actions'].filter(
                  (v) => !['forward', 'hold', 'greeting', 'queue', 'live_answer'].includes(v['action'])
                ).length === 0"
                class="routes-add-new-action"
              >
                <w-btn
                  v-if="!readonly"
                  text
                  changes-form
                  color="primary"
                  @click="add_action(i)"
                  class="pl-0 mx-auto"
                  block
                  :ripple="false"
                  :data-test-id="`route-conf-rule-${i}-add-action-btn`"
                >
                  <v-icon color="primary">mdi-plus-circle</v-icon> {{ l.t("call-flow.add-action", "Add next action") }}
              </w-btn>
              </div>
          </div>
        </draggable>
        <div class="route-add-new-rule">
          <w-btn
              v-if="!readonly"
              text
              changes-form
              color="primary"
              class="pl-0 mx-auto"
              block
              :ripple="false"
              @click="add_new_rule"
              data-test-id="route-conf-add-rule-btn"
            >
              <v-icon color="primary">mdi-plus-circle</v-icon>{{ l.t("call-flow.add-new-rule", "Add new rule") }}
          </w-btn>
        </div>
      </div>
    </div>
</template>

<script>
  import {emptyRouteRule} from 'helpers'
  import draggable from 'vuedraggable';
  import l from '../../../libs/lang';
  import FormInput from '../form/FormInput.vue';
  import RouteRuleFilter from './RouteRuleFilter.vue';
  import AddToListButton from '../AddToListButton.vue';
  import RouteRuleActions from './RouteRuleActions.vue';
  import RemoveFromListButton from '../RemoveFromListButton.vue';


  const generate_render_key = (prefix = 'actions') => `${prefix}-key-${Date.now()}`

  export default {
    props: ['id', '_route', '_extension', 'readonly'],
    components: {
      draggable,
      FormInput,
      RouteRuleFilter,
      AddToListButton,
      RouteRuleActions,
      RemoveFromListButton,
    },
    data() {
      return {
        l,
        loading: true,
        loading_message: null,
        alert: null,
        route: this.$props._route ? this.$props._route : null,
        extension: null,
        actions_key: generate_render_key(),
        rules_key: generate_render_key('rules'),
      };
    },
    watch: {
      route(value) {
        this.$emit('changed', value);
      },
      is_preset(value) {
        this.$emit('usesPreset', value);
      },
    },
    async created() {
      if (!this.$data.route) {
        if (this.$props.id) {
          this.$data.route = await this.$session.get_item(`/routes/${this.$props.id}`);
        } else {
          this.$data.route = { rules: [{ actions: [] }] };
        }
      }
      for (const index in this.$data.route.rules) {
        if (this.$data.route.rules[index]) {
          this.$data.route.rules[index].index = index;
        }
      }
      if (this.$props._extension || this.$data.route.extension) {
        this.$data.extension = this.$props._extension
          ? this.$props._extension
          : this.$data.route.extension;
      }
      this.$data.loading = false;
    },
    methods: {
      remove_rule(i) {
        this.$data.route.rules.splice(i, 1);
        if (!this.$data.route.rules.length) {
          setTimeout(() => {
            this.add_new_rule();
            this.$data.rules_key = generate_render_key('rules') 
          }, 100);
        }
        this.$data.rules_key = generate_render_key('rules') 
      },
      on_filter_update(index, data) {
        this.$data.route.rules[index].filter = data;
        this.add_empty_rule_if_needed();
      },
      add_empty_rule_if_needed() {
        const all_calls = this.$data.route.rules.find((x) => !x.filter);
        if (!all_calls) {
          this.add_new_rule();
        }
      },
      add_new_rule() {
        if (!this.$data.route.rules) {
          this.$data.route.rules = [];
        }
        const empty_rule = emptyRouteRule();
        if (this.$data.route.rules.length) {
          empty_rule.index = Date.now();
          this.$data.route.rules.push(empty_rule);
        } else {
          empty_rule.index = 1;
          this.$data.route.rules = [empty_rule];
        }
        this.$forceUpdate();
        return true;
      },
      add_action(rule_index) {
        this.$data.route.rules[rule_index].actions = [...this.$data.route.rules[rule_index].actions, { action: 'disconnect'}]
        this.$data.actions_key = generate_render_key() 
      },
      remove_action(rule_index, action_index) {
        this.$data.route.rules[rule_index].actions = this.$data.route.rules[rule_index].actions.filter((x, i) => i !== action_index)
        this.$data.actions_key = generate_render_key() 
      },
      action_action_changed(data, rule_index, action_index) {
        if (data === 'forward') {
          if (
            !(this.$data.route.rules[rule_index]
            && this.$data.route.rules[rule_index].actions[action_index]
            && Array.isArray(this.$data.route.rules[rule_index].actions[action_index].items))
          ) {
            this.$data.route.rules[rule_index].actions[action_index].items = [{ type: 'phone_number' }];
          }
        }
        if (data === 'hold' && !(this.$data.route.rules[rule_index].actions[action_index].hold_music)) {
          this.$data.route.rules[rule_index].actions[action_index].hold_music = null;
        }
        this.$data.actions_key = generate_render_key() 
      },
      blur_all_selectors() {
        // when dragging items, selectors would stay closed.
        // This isn't perfect, but didn't want to kill a fly with a cannonball
        const header = document.getElementById('large-header');
        if (header) header.click();
      }
    },
  };
</script>
