import { getVerbalColors } from 'helpers'

/**
 *
 */
const style = (t) => {
    const { colors, typography } = t['foundations-library']
    const verbalColors = getVerbalColors(colors)
    const coloring = {}
    for (const [verbal, color] of Object.entries(verbalColors)) {
        coloring[`&.notification--bg-${verbal}`] = {
            backgroundColor: color
        }
    }
    return {
        notification: {
            width: '20px',
            height: '20px',
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            color: 'white',
            ...typography.subtitle3,
            ...coloring
        }
    }
}

export default style
