import React from 'react'
import { Typography, Icon } from 'foundations-library/components'
import { makeStyles } from '@material-ui/core'

/**
 *
 */
const style = (t) => {
    const { colors } = t['foundations-library']
    return {
        container: {
            margin: 'auto',
            marginTop: '20px',
            width: '440px',
            height: '363px',
            borderRadius: '6px',
            padding: '20px',
            border: `1px solid ${colors.neutral[100]}`,
            '& .title': {
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                paddingBottom: '16px'
            },
            '& .billingPlan': {
                width: '100%',
                height: '102px',
                paddingBottom: '16px',
                marginBottom: '20px',
                borderBottom: `1px solid ${colors.neutral[100]}`
            },
            '& .billingPlanStatus': {
                padding: '5px 12px 6px 12px',
                marginTop: '6px 0px',
                backgroundColor: colors.secondary[50],
                borderRadius: '6px',
                marginLeft: '10px'
            },
            '& .includedItems': {
                display: 'flex',
                alignItems: 'center',
                marginBottom: '7px',
                '&:last-child': {
                    marginBottom: 0
                }
            }
        }
    }
}
const useStyles = makeStyles(style)

/**
 *
 */
const PlanDetails = (): JSX.Element => {
    const classes = useStyles()

    return (
        <div className={classes.container}>
            <div className={'title'}>
                <Typography data-testid='payment-method-title' variant='subtitle1' color={'secondary-500'} style={{ marginBottom: '16px' }}>Plan details</Typography>
            </div>
            <div className={'billingPlan'}>
                <Typography data-testid='billing-plan-title' variant='subtitle2' color={'secondary-500'} style={{ marginBottom: '6px' }}>Billing plan</Typography>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography data-testid='billing-plan-price' variant='subtitle2'>$12.74 Billed monthly</Typography>
                    <div className={'billingPlanStatus'}>
                        <Typography data-testid='billing-plan-staus' variant='buttonSmall' color={'secondary-700'}>Basic</Typography>
                    </div>
                </div>
                <Typography data-testid='number-users' variant='subtitle2'>Number of users: 6</Typography>
            </div>
            <Typography data-testid='included-in-plan' variant='captionLight' color={'neutral-500'} style={{ marginBottom: '10px' }}>Included in the basic plan:</Typography>
            <div className={'includedItems'}>
                <Icon name='check-alt' color={'neutral-800'} size={16}/>
                <Typography data-testid='included-in-plan-2' color={'neutral-800'} variant='body3' style={{ marginLeft: '10px' }}>500 Pooled minutes*</Typography>
            </div>
            <div className={'includedItems'}>
                <Icon name='check-alt' color={'neutral-800'} size={16}/>
                <Typography data-testid='included-in-plan-3' color={'neutral-800'} variant='body3' style={{ marginLeft: '10px' }}>1 Phone number per account</Typography>
            </div>
            <div className={'includedItems'}>
                <Icon name='check-alt' color={'neutral-800'} size={16}/>
                <Typography data-testid='included-in-plan-4' color={'neutral-800'} variant='body3' style={{ marginLeft: '10px' }}>Video conferencing with 10 participants</Typography>
            </div>
            <div className={'includedItems'}>
                <Icon name='check-alt' color={'neutral-800'} size={16}/>
                <Typography data-testid='included-in-plan-5' color={'neutral-800'} variant='body3' style={{ marginLeft: '10px' }}>Mobile and Web apps</Typography>
            </div>
            <div className={'includedItems'}>
                <Icon name='check-alt' color={'neutral-800'} size={16}/>
                <Typography data-testid='included-in-plannnn' color={'neutral-800'} variant='body3' style={{ marginLeft: '10px' }}>50 Standard voice features</Typography>
            </div>
        </div>
    )
}

/**
 *
 */
export { style }

export default PlanDetails
