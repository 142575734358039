
/**
 *
 */
const style = () => ({
    label: {
        display: 'inline-flex',
        alignItems: 'center',
        marginBottom: 0
    }
})

export default style
