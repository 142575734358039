import React from 'react'
import { Select } from 'foundations-library/components'
import { SelectType } from 'foundations-library/types'
import USStates from 'us-states'
import CanadaProvinces from 'canada-provinces'

interface StateSelectorProps extends Omit<SelectType, 'options'> {
    countryCode: string, // "CA" or "US",
}

/**
 * Select dropdown that allows the user to choose a province/state
 */
const StateSelector = ({ countryCode, ...props } : StateSelectorProps) : JSX.Element => {
    const options = (countryCode === 'CA' ? CanadaProvinces : USStates).map(s => ({ value: s.abbreviation, text: s.name }))
    return (
        <Select
            {...props}
            options={options}
            name="state"
            title={countryCode === 'CA' ? 'Province' : 'State'}
        />
    )
}

export default StateSelector
