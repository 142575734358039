/**
 *
 */
const style = () => ({
    statements: {
        maxWidth: '900px',
        margin: '40px auto 0 auto',
        '& .billing-icon': {
            marginRight: '8px'
        },
        '& .download-all-button-wrapper': {
            marginBottom: '20px',
            textAlign: 'right'
        },
        '& .get-file-from-list-button': {
            cursor: 'pointer'
        }
    }
})

export default style
