import l from '../lang'

/**
 *
 * @param {string, string} default_message
 * @param {*} message
 * @returns
 */
const generate_error_message = (default_message, message) => {
    if (message) return message
    return default_message
}
export default {
    /**
     *
     * @param {object} route
     * @param {string} message
     * @returns
     */
    all_calls_required: (route, message) => () => {
        if (route.rules && !route.rules.find((x) => !x.filter)) {
            return generate_error_message(
                l.t('routes.all-call-is-required', 'To insure all calls are received properly, a rule for \'All calls\' has been added as your last call handling rule. This helps account for possible incoming call types that may have been overlooked. You can now save your changes.'),
                message
            )
        }

        return true
    },
    /**
     *
     * @param {object} route
     * @param {string} message
     * @returns
     */
    forward_call_has_destination: (route, message) => () => {
        if (route.rules &&
            route.rules.find(
                (rule) => rule.actions.find(
                    (action) => action.action === 'forward' && action.items.find(
                        (item) => (item.type === 'phone_number' && !item.number) || (item.type === 'extension' && !item.extension)
                    )
                )
            )) {
            return generate_error_message(
                l.t('routes.all-fwd-calls-required', 'To insure all calls are received properly, a rule for \'Forward calls\' have to have proper number or extension.'),
                message
            )
        }

        return true
    },
    /**
     *
     * @param {Array, Object} values
     * @param {string} message
     * @returns
     */
    required_values: (values, message) => {
        let empty_values = []
        let default_message = l.t('app.please-check-the-required-fileds', 'Please check the required fileds')
        if (Array.isArray(values)) {
            empty_values = values.filter((x) => !x)
        } else if (values && (typeof values === 'object')) {
            for (const key of Object.keys(values)) {
                if (!values[key]) empty_values.push(key)
            }

            default_message = `${l.t('app.required-fields', 'Required fields')}: ${empty_values.join(', ')}`
        }
        if (!empty_values.length) {
            return generate_error_message(
                default_message,
                message
            )
        }

        return true
    }
}
