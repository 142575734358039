/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import api from 'account/src/util/api_v5'
import { Icon, Table, Typography, Button, Alert } from 'foundations-library/components'
import { makeStyles } from '@material-ui/core'
import style from './style'
import { formatPrice } from 'formatters'
import Actions from './components/Actions'
import ListLoader from './components/ListLoader'
import { generateResultAlertDownloadAllPdf, formatDate } from './helpers/list'

const useStyles = makeStyles(style)
const linkAccessor = '_linkComponent'

type dataType = Record<string, unknown>[] | undefined

const sortingCb = (field, order, data): dataType => {
    if (field) {
        let sortingField
        if (field === 'formattedDate') {
            sortingField = 'timestamp'
        } else if (field === 'formattedPrice') {
            sortingField = 'total_charges_in_cents'
        }
        if (sortingField) {
            const sorted = data.sort((a, b) => order === 'asc' ? a[sortingField] - b[sortingField] : b[sortingField] - a[sortingField])
            return sorted
        }
    }
}

/**
 *
 */
const Statements = () => {
    const styles = useStyles()

    const [apiResponse, setApiResponse] = useState(null)
    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState(null)
    const [loadingPdfIds, setLoadingPdfIds]: number = useState([])
    const [loadingPdfs, setLoadingPdfs] = useState(false)

    const getInvoices = useCallback(() => {
        if (apiResponse) return apiResponse
        setLoading(true)
        api.getInvoices().then((res) => {
            setApiResponse(res)
            setLoading(false)
        })
    }, [])

    useEffect(getInvoices, [])

    const openPdf = async (invoiceId) => {
        // Create a blank popup immediately on user action to avoid browser popup blockers
        setLoadingPdfIds([...loadingPdfIds, invoiceId])
        const invoiceTab = window.open('', '_blank')
        if (invoiceTab) invoiceTab.document.write('Loading invoice - please wait...')
        const res = await api.getInvoicePdf(invoiceId)
        if (invoiceTab && res && res.invoice_link) {
            invoiceTab.location.href = res.invoice_link
        } else if (invoiceTab) {
            invoiceTab.document.write('Could not load invoice')
        }
        setLoadingPdfIds(loadingPdfIds.filter((x) => x === invoiceId))
    }

    const downloadPdf = async (invoiceId, supressAlert = false) => {
        if (!apiResponse || (apiResponse && !apiResponse.statement_list)) return apiResponse

        setLoadingPdfIds([...loadingPdfIds, invoiceId])
        let result
        let fileName
        try {
            fileName = `${formatDate((apiResponse.statement_list.find((x) => x.statement_id === invoiceId)).billing_period)}.pdf`
        } catch (e) {
            console.log(e)
            fileName = `${invoiceId}.pdf`
        }
        try {
            const res = await api.getInvoicePdf(invoiceId)

            const response = await fetch(res.invoice_link)
            const blob = await response.blob()
            const fileURL = window.URL.createObjectURL(blob)
            const alink = document.createElement('a')
            alink.href = fileURL
            alink.download = fileName
            alink.click()
            result = { level: 'success', message: `${fileName} downloaded`, filename: fileName }
        } catch (err) {
            result = { level: 'error', message: 'Something went wrong', filename: fileName }
        }
        if (!supressAlert) setAlert(result)
        setLoadingPdfIds(loadingPdfIds.filter((x) => x === invoiceId))
        return result
    }

    const downloadAll = async () => {
        if (!apiResponse || (apiResponse && !apiResponse.statement_list)) return apiResponse

        setLoadingPdfs(true)
        const promises = []
        for (const statement of apiResponse.statement_list) {
            promises.push(downloadPdf(statement.statement_id, true))
        }
        const results = await Promise.all(promises)

        setAlert(generateResultAlertDownloadAllPdf(results))
        setLoadingPdfs(false)
    }

    const tableData = useMemo(() => {
        if (!apiResponse) return null
        const formattedData = apiResponse.statement_list.map((x, i) => {
            const date = formatDate(x.billing_period)
            // .toLocaleString('default', { day: 'numeric',  month: 'short', year: 'numeric' })
            x[linkAccessor] = <span className='get-file-from-list-button' onClick={() => openPdf(x.statement_id)}>
                <Icon name='billing' size={20} color='primary-500' className='billing-icon' />
                <Typography tag='span' variant="subtitle3" color="primary">{date}</Typography>
            </span>
            x.formattedDate = date
            x.formattedPrice = formatPrice(x.total_charges_in_cents, '$')
            const loading = loadingPdfIds.includes(x.statement_id) || loadingPdfs
            x.actions = <Actions
                loading={loading}
                data-testid={`actions-button-${x.statement_id}`}
                item={x} options={[
                    { text: 'View', value: 'view', cb: openPdf },
                    { text: 'Download', value: 'download', cb: downloadPdf }
                ]}
            />
            return x
        })
        return formattedData
    }, [apiResponse, loadingPdfIds, loadingPdfs])

    return <div className={styles.statements} data-testid='statements'>
        {loading && <ListLoader />}
        {!loading && apiResponse && tableData && <div data-testid='statements-content'>
            {alert && <Alert level={alert.level} autoclose onClose={() => setAlert(null)} marginY={20} data-testid='statements-alert'>{alert.message}</Alert>}
            <div
                className='download-all-button-wrapper'
            >
                <Button
                    onClick={downloadAll}
                    icon='download'
                    disabled={loadingPdfs}
                    data-testid='statements-download-button'
                >Download all</Button>
            </div>
            <Table columns={[
                { label: 'Invoice', accessor: linkAccessor, width: '75%' },
                { label: 'Billing date', accessor: 'formattedDate', sortable: true },
                { label: 'Payments', accessor: 'formattedPrice', sortable: true },
                { label: '', accessor: 'actions' }
            ]} data={tableData}
            sortingCb={sortingCb}
            data-testid='statements-table'
            />
        </div>}
    </div>
}

export default Statements
