import React, { useState, useEffect } from 'react'
import { Typography, IconButton, Badge /*, Menu */ } from 'foundations-library/components'
import Menu from 'foundations-library/src/components/Menu/Menu'
import { CreditCardType } from 'interfaces'

import Amex from '../../../../../util/credit-cards-images/amex.png'
import Diners from '../../../../../util/credit-cards-images/diners.png'
import Discover from '../../../../../util/credit-cards-images/discover.png'
import JCB from '../../../../../util/credit-cards-images/jcb.png'
import MasterCard from '../../../../../util/credit-cards-images/mastercard.png'
import UnionPay from '../../../../../util/credit-cards-images/unionpay.png'
import Visa from '../../../../../util/credit-cards-images/visa.png'
import { getValue } from 'remote-config-value'
import { makeStyles } from '@material-ui/core'

const remoteConfigID = 'credit_card_expiring_soon_in_days'

const style = (t) => {
    const { colors } = t['foundations-library']
    return {
        listItem: {
            padding: '15px',
            border: `1px solid ${colors.neutral[200]}`,
            borderRadius: '6px',
            display: 'flex',
            justifyContent: 'space-between',
            '&:not(:first-child)': {
                marginTop: '10px'
            },
            '& .item-info': {
                display: 'flex',
                '& .img-wrapper': {
                    border: `1px solid ${colors.neutral[100]}`,
                    borderRadius: '5px',
                    padding: '10px',
                    width: '82px',
                    height: '46px',
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    marginRight: '15px'
                },
                '& .item-details': {
                    '& .ending-in': {
                        marginBottom: '4px'
                    },
                    '& .exp-data-info': {
                        display: 'flex',
                        '& .expiring-soon': {
                            marginLeft: '10px',
                            fontWeight: 700
                        }
                    }
                }
            },
            '& .item-actions': {
                display: 'inline-flex',
                alignItems: 'center',
                '& .item-actions-btn': {
                    marginLeft: '15px'
                }
            }
        }
    }
}
const useStyles = makeStyles(style)

const images = {
    JCB,
    Visa,
    UnionPay,
    Discover,
    MasterCard,
    'Diners Club': Diners,
    'American Express': Amex
}
const getBrandImg = (c) => {
    if (c.details && c.details.brand && images[c.details.brand]) {
        return <img
            src={images[c.details.brand]}
            alt={c.details.brand}
            height={c.details && c.details.brand === 'MasterCard' ? '26' : '20'}
            data-testid={`card-img-${c.id}`}
        />
    }
    if (c.details && c.details.brand) {
        return <span>
            {c.details.brand}
        </span>
    }

    return <>&mdash;</>
}

const addZeroIfNeeded = (n) => n < 10 ? `0${n}` : n

type MonthAndDateObj = {month: number, year: number}

const getExpDate = (card): MonthAndDateObj => {
    let expYear = card?.details?.exp_year
    let expMonth = card?.details?.exp_month
    if (!expYear) {
        expYear = card.cc_exp.slice(-2)
    }
    if (!expMonth) {
        expMonth = card.cc_exp.slice(0, 2)
    }

    return {
        month: Number.parseInt(expMonth),
        year: Number.parseInt(expYear)
    }
}

const timeUntilExpiration = (exp: MonthAndDateObj): number => {
    const expDate = new Date(exp.year, exp.month)
    // this takes monthIndex (0-11), and we have 1-12, that will create first of the next month. It should be like that

    return expDate.getTime() - (new Date()).getTime()
}

type OptionType = {
    text: string;
    cb(id): any;
}

interface PropsInterface {
    item: CreditCardType;
    disabled?: boolean;
    options: OptionType[];
    onClick?(id): any;
    className?: string;
    'data-testid'?: string;
}

/**
 *
 */
const ListItem = ({
    item,
    disabled = false,
    options,
    onClick,
    className,
    'data-testid': dataTestId
}: PropsInterface): JSX.Element => {
    const styles = useStyles()
    const expDateObj = getExpDate(item)
    const timeUntilExp = timeUntilExpiration(expDateObj)
    const expired = timeUntilExp <= 0

    const [expiringSoon, setExpiringSoon] = useState(false)
    const [menuIsOpen, setMenuIsOpen] = useState(false)

    const toggleMenu = () => {
        setMenuIsOpen(!menuIsOpen)
    }
    useEffect(() => {
        const checkExpiringSoon = async () => {
            if (expired) return false
            const days = await getValue(remoteConfigID)
            setExpiringSoon(timeUntilExp < (1000 * 60 * 60 * 24 * days))
        }
        checkExpiringSoon()
    }, [])

    const onOptionSelect = (opt) => {
        const v = typeof opt === 'object' && opt ? opt.value : opt
        const option = options.find((x) => x.value === v)
        if (option) option.cb(item.id)
    }

    return (
        <div
            className={`${styles.listItem} ${className || ''} payment-methods--list-item`}
            onClick={() => onClick && onClick(item.id)}
            data-testid={dataTestId}
        >
            <div className="item-info">
                <div className="img-wrapper">
                    {getBrandImg(item)}
                </div>
                <div className='item-details'>
                    <Typography
                        variant="subtitle1"
                        className="ending-in"
                        data-testid={`${dataTestId}-ending-in`}
                    >
                        {item?.details?.brand || ''} ending in {item.details?.last4 || item.cc_number.slice(-4)}
                    </Typography>
                    <div className='exp-data-info'>
                        <Typography
                            variant="body3"
                            className="expires"
                            color={expired ? 'destructive-500' : 'neutral-500'}
                            data-testid={`${dataTestId}-expires`}
                        >
                            {expired ? 'Expired' : 'Expires'} {addZeroIfNeeded(expDateObj.month)}/{expDateObj.year}
                        </Typography>
                        {expiringSoon && <Typography variant="body3" color='destructive-500' className="expiring-soon" data-testid={`${dataTestId}-expiring-soon`}>Expiring soon...</Typography>}
                    </div>
                </div>
            </div>
            <div className='item-actions'>
                {item.status === 'primary' && <Badge data-testid='primary-pm-badge'>Primary</Badge>}
                {options && options.length > 0 && <>
                    <IconButton
                        disabled={disabled}
                        size='small'
                        variant='icon'
                        name='more-vert'
                        color='neutral'
                        onClick={toggleMenu}
                        className='item-actions-btn'
                        data-testid={`${dataTestId}-options-trigger`}
                    />
                    <Menu
                        isOpen={menuIsOpen}
                        onClose={() => setMenuIsOpen(false)}
                        onChange={onOptionSelect}
                        withIcon={true}
                        options={options}
                        data-testid={`${dataTestId}-options-menu`}
                    />
                </>}
            </div>
        </div>
    )
}

export default ListItem
