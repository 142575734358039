import React from 'react'
import { AnimationType, getAnimationClass } from './animations/AnimationType'
import './animations/style.css'

interface PropsInterface extends React.SVGProps<SVGSVGElement> {
  size?: number;
  animation?: AnimationType
}

const Icon = ({
    size = 24,
    viewBox = '0 0 24 24',
    children,
    animation,
    ...props
}: PropsInterface): JSX.Element => (<svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox={viewBox}
    {...props}
    className={`${getAnimationClass(animation)} ${props.className}`}
>
    {children}
</svg>)

/**
 *
 */
const svgGenerator = (path: React.ReactNode): React.MemoExoticComponent<React.ComponentType> => {
    const svg = (props) => <Icon {...props}><>{path}</></Icon>

    return React.memo(svg)
}

export default svgGenerator
