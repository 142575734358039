/* eslint-disable react/no-danger-with-children */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'
import { Typography, Icon, Button } from 'foundations-library/components'
import { makeStyles } from '@material-ui/core'
import { Dialog } from 'functional-foundations'
import { DialogSelector } from 'payment-methods'
import CardDetails from './CardDetails'
import BillingLoader from './BillingLoader'
import API from '../../account/src/util/api_v5.js'

/**
 *
 */
const style = (t, account) => {
    const { colors } = t['foundations-library']
    return {
        container: {
            margin: 'auto',
            width: '440px',
            height: `${account?.cb_is_past_due ? '279px' : '209'}`,
            borderRadius: '6px',
            padding: '20px',
            border: `1px solid ${colors.neutral[100]}`,
            '& .title': {
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '22px',
                letterSpacing: '-0.2px',
                color: colors.neutral[900]
            },
            '& .alertContainer': {
                width: '400px',
                height: '54px',
                border: `1px solid ${colors.destructive[200]}`,
                backgroundColor: colors.destructive[50],
                color: colors.destructive[500],
                borderRadius: '28px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '8px 10px 8px 20px',
                marginTop: '16px'
            },
            '& .payButton': {
                display: 'flex',
                justifyContent: 'end'
            },
            '& .dialog': {
                width: '396px',
                height: '54px',
                border: `1px solid ${colors.destructive[200]}`,
                backgroundColor: colors.destructive[50],
                color: colors.destructive[500],
                borderRadius: '28px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '8px 10px 8px 20px'
            },
            '& .paymentDate': {
                height: '43px',
                borderBottom: `1px solid ${colors.neutral[100]}`,
                display: 'flex',
                alignItems: 'center',
                paddingBottom: '20px',
                marginBottom: '20px',
                marginTop: '10px'
            },
            '& .paymentMethod': {
                margin: 'auto',
                marginBottom: '30px',
                height: '119px',
                '& .info': {
                    display: 'flex',
                    justifyContent: 'space-between',
                    height: '56px',
                    marginBottom: '4px',
                    '& .creditCard': {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '82px',
                        height: '46px',
                        borderRadius: '5px',
                        padding: '10px',
                        border: `1px solid ${colors.neutral[50]}`
                    },
                    '& .creditCardInfo': {
                        width: '256px'
                    }
                }
            },
            '& .actionButtons': {
                width: '100%',
                display: 'flex',
                justifyContent: 'center'
            },
            '& .paymentPrice': {
                margin: 'auto',
                height: '220px',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column'
            },
            '& .successDialogBtn': {
                display: 'flex',
                justifyContent: 'center',
                paddingTop: '30px',
                width: '100%'
            },
            '& .biggerHeight': {
                height: '300px !important'
            },
            '& .phoneNumber': {
                color: colors.primary[400]
            }
        }
    }
}

/**
 *
 */
export { style }

const useStyles = makeStyles(style)
interface PropsInterface {
    account?: any
    value?: any
    primaryCard?: any
    allCards: any
    fetchData(): any
}

/**
 *
 */
const CurrentBalance = ({
    account,
    primaryCard = {},
    allCards,
    fetchData
}: PropsInterface): JSX.Element => {
    const [isOpenPayNow, setIsOpenPayNow] = useState(false)
    const [isOpenSelector, setIsOpenSelector] = useState(false)
    const [selectedCard, setSelectedCard] = useState(primaryCard)
    const [status, setStatus] = useState('')
    const [loading, setLoading] = useState(false)

    const classes = useStyles()

    const handleOnChange = (val) => {
        if (val) {
            const found = allCards.find(x => x.id === val)
            setSelectedCard(found)
            setIsOpenPayNow(true)
            setIsOpenSelector(false)
        }
    }

    const handleOnConfirm = async () => {
        try {
            setLoading(true)

            const id = selectedCard?.id

            if (!account?.cb_current_balance || !id) {
                setStatus('error')
                setLoading(false)
                return
            }

            const payload = {
                amount: account?.cb_current_balance,
                payment_info_id: id
            }

            const response = await API.submitPayment(payload)
            if (response.data.status === 'success') {
                fetchData && fetchData()
            }
            if (response?.data?.status) {
                setStatus(response.data.status)
            } else if (response?.http_code === 500) {
                setStatus('error')
            }
        } catch (error) {
            setStatus('error')
        } finally {
            setLoading(false)
        }
    }

    const closeModal = () => {
        setIsOpenPayNow(false)
        setStatus('')
        setLoading(false)
    }

    const changePM = () => {
        setLoading(true)
        setIsOpenSelector(true)
        setIsOpenPayNow(false)
        setTimeout(() => {
            setLoading(false)
        }, 2000)
    }

    const closeDialogSelector = () => {
        setIsOpenSelector(false)
        setStatus('')
    }

    const displayLastPayment = () => {
        const amount = account?.cb_last_payment_amount
        return !(amount === 0 || amount?.includes('$0.00'))
    }

    const disablePayNow = () => {
        return account?.cb_current_balance.includes('$0.00') || account?.cb_current_balance.includes('CR')
    }

    useEffect(() => {
        setSelectedCard(primaryCard)
    }, [primaryCard])

    return (
        <div className={classes.container}>
            <div className={'title'}>Current balance</div>
            <Typography data-testid='current-balance-value' variant='h2' style={{ marginBottom: '20px' }}>{account?.cb_current_balance || '-'}</Typography>
            {displayLastPayment() && <Typography data-testid='current-balance-last-payment' variant='captionLight' color={'neutral-500'} style={{ marginBottom: '10px' }}>
                {`Your last payment of ${account?.cb_last_payment_amount} was received on ${account?.cb_last_payment_date || '-'}.`}
            </Typography>}
            <div style={{ display: 'flex', marginBottom: '10px' }}>
                <Typography data-testid='billing-cycle' variant='subtitle3' style={{ marginRight: '4px' }}>Billing cycle:</Typography>
                <Typography data-testid='billing-cycle-date-range' variant='body3' color={'neutral-600'}>{account?.cb_billing_cycle || '-'}</Typography>
            </div>
            {account?.cb_autopay && <Typography data-testid='enrolled-autopay' variant='body4' color={'neutral-600'}>Youre enrolled in autopay</Typography>}
            {!!account?.cb_is_past_due &&
            <div className={'alertContainer'}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Icon name='alert' color={'destructive-500'} size={24}/>
                    <div style={{ marginLeft: '6px' }}>
                        <Typography data-testid='payment-overdue' variant='subtitle3' color={'destructive-500'}>Your payment is overdue</Typography>
                        <Typography data-testid='payment-due-upon-receipt' variant='subtitle3' color={'destructive-500'}>Payment due upon receipt</Typography>
                    </div>
                </div>
                <Button color={'primary'} disabled={disablePayNow()} size={'large'} onClick={() => setIsOpenPayNow(true)}>Pay now</Button>
            </div>}
            {!account?.cb_is_past_due &&
            <div className={'payButton'}>
                <Button color={'primary'} disabled={disablePayNow()}size={'large'} onClick={() => setIsOpenPayNow(true)}>Pay now</Button>
            </div>}
            <Dialog
                title={(loading || status) ? '' : 'Make a payment'}
                isOpen={isOpenPayNow}
                onClose={closeModal}
            >
                <>
                    {loading && <BillingLoader/>}
                    {!loading && !status && <>
                        <Typography data-testid='total-balance' variant='subtitle1' style={{ marginBottom: '20px' }}>Total balance</Typography>
                        <Typography data-testid='total-balance-value' variant='h4'>{account?.cb_current_balance || '-'}</Typography>
                        <div className={'paymentDate'}>
                            <Typography data-testid='payment-date-label' variant='subtitle1' color={'secondary-500'}>Payment date</Typography>
                            <Typography data-testid='payment-date' variant='subtitle2' color={'neutral-600'} style={{ marginLeft: '12px' }}>{`${account?.cb_billing_cycle || '-'}`}</Typography>
                        </div>
                        <div className={'paymentMethod'}>
                            {selectedCard && <CardDetails primaryCard={selectedCard} changePM={changePM}/>}
                        </div>
                        <div className='actionButtons'>
                            <Button color={'neutral'} size={'large'} onClick={closeModal} style={{ marginRight: '20px' }}>Cancel</Button>
                            <Button color={'primary'} size={'large'} disabled={!selectedCard.id} onClick={handleOnConfirm}>{`Submit payment for ${account?.cb_current_balance || '-'}`}</Button>
                        </div>
                    </>}
                    {status === 'error' &&
                    <div className='paymentPrice biggerHeight'>
                        <Icon name='cancel' size={60} color='destructive-600' />
                        <Typography
                            data-testid='error-message'
                            variant='h6'
                            color='destructive-600'
                            style={{ marginTop: '20px' }}
                        >
                        Oops, something went wrong!
                        </Typography>
                        <Typography data-testid='error-description' variant='body1' color='neutral-700'> There was an issue processing your request.</Typography>
                        <Typography
                            data-testid='error-payment-price'
                            variant='h4'
                            style={{ marginTop: '20px' }}
                        >
                            {account?.cb_current_balance || '-'}
                        </Typography>
                        <Typography
                            data-testid='error-transaction-id'
                            variant='body1'
                            color='neutral-700'
                            style={{ marginTop: '10px' }}
                        >
                        Transaction ID: <span>123456789b</span>
                        </Typography>
                        <Typography
                            data-testid='error-contact-info'
                            variant='subtitle1'
                            style={{ marginTop: '55px' }}
                        >
                        Please call <span className='phoneNumber'>(877) 7466 310</span>
                        </Typography>
                    </div>
                    }
                    {status === 'success' &&
                    <>
                        <div className='paymentPrice'>
                            <Icon name='check-alt' size={60} color='secondary-600' />
                            <Typography
                                data-testid='success-message'
                                variant='h6'
                                style={{ margin: '20px 0px' }}
                            >
                                Thank you for your payment!
                            </Typography>
                            <Typography
                                data-testid='success-payment-price'
                                variant='h4'
                                style={{ marginBottom: '10px' }}
                            >
                                {account?.cb_current_balance || '-'}
                            </Typography>
                            <Typography data-testid='success-payment-id' variant='body1' color='secondary-500'>
                                Payment ID: <span>123456789b</span>
                            </Typography>
                        </div>
                        <div className='successDialogBtn'>
                            <Button
                                data-testid='success-ok-button'
                                color='primary'
                                size='large'
                                onClick={closeModal}
                            >
                            Okay
                            </Button>
                        </div>
                    </>
                    }
                </>
            </Dialog>
            <div key={`${isOpenSelector}`}>
                <DialogSelector isOpen={isOpenSelector} value={selectedCard?.id} onConfirm={handleOnChange} onClose={closeDialogSelector}/>
            </div>
        </div>
    )
}

export default CurrentBalance
