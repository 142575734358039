import React, { useEffect, useState } from 'react'
import { Tabs } from 'foundations-library/components'
import style from './style'
import { makeStyles } from '@material-ui/core'
import { Switch, Route, Redirect } from 'react-router-dom'
import PaymentMethods from 'payment-methods'
import Statements from 'statements'
import BillingOverview from 'billing-overview'

const useStyles = makeStyles(style)

interface PropsInterface {
    routeProps: any;
    smallView: boolean;
}

/**
 *
 */
const PayNow = ({
    routeProps,
    smallView
}: PropsInterface): JSX.Element => {
    const styles = useStyles()
    const basePath = routeProps?.match?.path
    const [activeTab, setActiveTab] = useState('')

    useEffect(() => pushRoute(activeTab), [activeTab])
    const pushRoute = newTab => routeProps.history.push(`${routeProps.match.path}${newTab}`)

    return <div className={styles.payNow} data-testid="pay-now">
        <Tabs
            value={activeTab}
            items={[
                { value: '', text: 'Overview' },
                { value: 'statements', text: 'Statements' },
                { value: 'payment-methods', text: 'Payment methods' }
            ]}
            onChange={setActiveTab}
            className={styles.tabs}
            data-testid='pay-now-tabs'
        />
        <div className="" data-testid="pay-now-content">
            <Switch>
                <Route path={`${basePath}statements`} render={() => <Statements />} />
                <Route path={`${basePath}payment-methods`} render={() => <PaymentMethods routeProps={routeProps} smallView={smallView} />}/>
                <Route path={`${basePath}`} render={() => <BillingOverview routeProps={routeProps} setActiveTab={setActiveTab} />} />
                <Route path='/' render={() => <Redirect to={`${basePath}`}/>} />
            </Switch>
        </div>
    </div>
}

export default PayNow
