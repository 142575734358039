import React, { useState } from 'react'
import Icon from '../Icon/Icon'
import { makeStyles } from '@material-ui/core'
import ColumnType from './Column.interface'

const style = (t) => {
    const { colors } = t['foundations-library']
    return {
        tableHead: {
            '& th': {
                paddingRight: '20px',
                fontWeight: 600,
                fontSize: '12px',
                lineHeight: '20px',
                fontFamily: 'Inter',
                color: colors.secondary[500],
                textTransform: 'uppercase',
                height: '34px',
                borderBottom: '1px solid rgba(28, 153, 128, 0.5)',
                borderTop: '1px solid rgba(28, 153, 128, 1)',
                minWidth: '100px',
                whiteSpace: 'nowrap',
                '&.th--sortable': {
                    cursor: 'pointer'
                },
                '& .table-head--icon': {
                    marginLeft: '5px'
                }
            }
        }
    }
}

const useStyles = makeStyles(style)

interface PropsInterface {
    columns: ColumnType[];
    handleSorting?(accessor, sortOrder): any;
    'data-testid'?: string;
}

/**
 *
 */
const TableHead = ({
    columns,
    handleSorting,
    'data-testid': dataTestId = 'table-head'
}: PropsInterface): JSX.Element => {
    const styles = useStyles()

    const [sortField, setSortField] = useState('')
    const [order, setOrder] = useState('asc')

    const handleSortingChange = (accessor) => {
        const sortOrder = accessor === sortField && order === 'asc' ? 'desc' : 'asc'
        setSortField(accessor)
        setOrder(sortOrder)
        handleSorting(accessor, sortOrder)
    }

    return (
        <thead className={styles.tableHead} data-testid={dataTestId}>
            <tr data-testid={`${dataTestId}-row`}>
                {columns.map(({ label, accessor, sortable, width }, i) => {
                    let icon = ''
                    if (sortable) {
                        if (sortField === accessor && order === 'asc') {
                            icon = 'down-alt'
                        } else if (sortField === accessor && order === 'desc') {
                            icon = 'up-alt'
                        } else {
                            icon = 'sort-light'
                        }
                    }
                    return (
                        <th
                            key={accessor}
                            onClick={() => sortable ? handleSortingChange(accessor) : null}
                            style={{ width }}
                            className={sortable ? 'th--sortable' : ''}
                            data-testid={`${dataTestId}-data-${i}`}
                        >
                            {label}
                            {icon && <Icon name={icon} color='secondary' variant='icon' size='small' className='table-head--icon' data-testid={`${dataTestId}-data-${i}-icon`}/>}
                        </th>
                    )
                })}
            </tr>
        </thead>
    )
}
export default TableHead
