
/**
 *
 */
const style = (t) => {
    const { colors } = t['foundations-library']
    return {
        tabs: {
            '&:not(.tabs--textual)': {
                overflow: 'hidden',
                borderRadius: '25px',
                backgroundColor: colors.primary[50],
                padding: '3px',
                display: 'flex',
                justifyContent: 'space-evenly',
                width: '100%'
            }
        }
    }
}

export default style
