import React from 'react'
import { Button } from 'foundations-library/components'
import { makeStyles } from '@material-ui/core'
import { ButtonType } from 'foundations-library/types'

const useStyles = makeStyles(() => ({
    formButtons: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '30px'
    },
    cancelBtn: {
        marginRight: '20px'
    }
}))

interface PropsInterface {
    onCancel?(): any
    onConfirm?(): any
    disableConfirm?: boolean
    cancelText?: string
    confirmText?: string
    cancelProps?: ButtonType
    confirmProps?: ButtonType
    'data-testid'?: string;
}

/**
 *
 */
const FormButtons = ({
    onCancel,
    onConfirm,
    disableConfirm = false,
    cancelText = 'Cancel',
    confirmText = 'Save',
    cancelProps = {},
    confirmProps = {},
    'data-testid': dataTestId = 'form-buttons'
}: PropsInterface): JSX.Element => {
    const styles = useStyles()
    const cancelBtnProps = {
        color: 'neutral',
        size: 'large',
        ...cancelProps,
        type: 'button'
    }
    const confirmBtnProps = {
        color: 'primary',
        size: 'large',
        ...confirmProps,
        type: 'button',
        disabled: disableConfirm
    }
    if (onCancel) cancelBtnProps.onClick = onCancel
    if (onCancel) confirmBtnProps.onClick = onConfirm
    return <div className={styles.formButtons} data-testid={dataTestId}>
        { onCancel && <Button {...cancelBtnProps} className={styles.cancelBtn} data-testid={`${dataTestId}-cancel`}>{cancelText}</Button> }
        { onConfirm && <Button {...confirmBtnProps} data-testid={`${dataTestId}-confirm`}>{confirmText}</Button> }
    </div>
}

/**
 *
 */
export type FormButtonsInterface = PropsInterface
export default FormButtons
