import { getVerbalColors } from 'helpers'

/**
 *
 */
const style = theme => {
    const { colors } = theme['foundations-library']
    const verbalColors = getVerbalColors(colors)

    return {
        tableContainer: {
            overflowX: 'auto',
            '& table': {
                width: '100%'
            },
            '& tr': { height: '80px' },
            '& tr:first-child': {
                height: '34px',
                borderRadius: '5px',
                backgroundColor: 'rgba(211, 235, 230, 0.60)',
                '& th': {
                    padding: '0 6px',
                    '&:first-child': {
                        borderRadius: '5px 0 0 5px'
                    },
                    '&:last-child': {
                        borderRadius: '0 5px 5px 0'
                    }
                }
            },
            '& tr:not(:first-child)': {
                borderBottom: `1px solid ${verbalColors['neutral-100']}`,
                '& td': {
                    padding: '0 6px'
                }
            }
        }
    }
}

export default style
