import React from 'react'
import { Skeleton } from 'foundations-library/components'

/**
 *
 */
const BillingLoader = (): JSX.Element => {
    return <div data-testid='billing-loader'>
        {[1, 2, 3].map((x) => <Skeleton variant='rect' height='78px' width='100%' style={{ marginBottom: '20px' }} key={`pm-loader-${x}`}/>)}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Skeleton variant='rect' height='36px' width='110px' style={{ marginBottom: '20px', marginRight: '15px' }}/>
            <Skeleton variant='rect' height='36px' width='110px' style={{ marginBottom: '20px' }}/>
        </div>
    </div>
}

export default BillingLoader
