import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import style from './style'
import CSS from 'csstype'

type Size = {
    width?: number | string,
    height?: number | string,
    margin?: number | string
}

/**
 *
 */
export type SkeletonProps = {
    variant: 'text' | 'circle' | 'rect',
    animation?: 'pulse' | 'wave' | false,
    className?: string,
    style?: CSS.Properties,
    'data-testid'?: string
} & Size

const useStyles = makeStyles(style)

/**
 *
 */
const Skeleton = (props: SkeletonProps) => {
    const {
        variant,
        className,
        animation,
        style,
        'data-testid': dataTestId = 'skeleton'
    } = props
    const classes = useStyles(props)

    const getAnimationClass = () => {
        if (animation === 'pulse' || animation === undefined) return classes.pulse
        if (animation === 'wave') return classes.wave
        if (animation === false) return classes.noAnimation
        return ''
    }

    return (
        <div className={`${classes.skeleton} ${classes[variant]} ${getAnimationClass()} ${className} skeleton`} style={style} data-testid={dataTestId} />
    )
}

export default Skeleton
