import React from 'react'
import CommonInput from '../CommonInput/CommonInput'
import CommonInputType from '../../helpers/types/CommonInput.interface'

type HtmlInputProps = Omit<React.HTMLProps<HTMLInputElement>, 'children' | 'onChange' | 'size'>

interface PropsInterface extends HtmlInputProps, CommonInputType {
    onChange?(v): any;
}

/**
 *
 */
const TextField = ({
    size = 'large',
    rules,
    isValid,
    helperText,
    onChange,
    onClear,
    style,
    'data-testid': dataTestId = 'text-field',
    fullWidth = false,
    className,
    ...props
}: PropsInterface): JSX.Element => {
    const handleOnChange = (e) => {
        if (onChange && e.target) {
            return onChange(e.target.value)
        }
    }

    return (
        <CommonInput
            value={ props.value }
            rules={rules}
            isValid={isValid}
            size={size}
            helperText={helperText}
            style={style}
            onClear={onClear}
            fullWidth={fullWidth}
            clearable={true}
            className={className}
            label={props.label}
            data-testid={dataTestId}
            leadingIcon={props.leadingIcon}
            onClickLeadingIcon={props.onClickLeadingIcon}
            trailingIcon={props.trailingIcon}
            onClickTrailingIcon={props.onClickTrailingIcon}
            disabled={props.disabled}
        >
            <input {...props} className='text-field input' onChange={handleOnChange} data-testid={dataTestId}/>
        </CommonInput>
    )
}

/**
 *
 */
export type TextFieldType = PropsInterface

export default TextField
