import { SkeletonProps } from './Skeleton'
/**
 *
 */
const style = () => ({
    skeleton: {
        opacity: 0.7,
        cursor: 'progress'
    },
    pulse: {
        animation: '$skeleton-pulse-loading 1s linear infinite alternate'
    },
    wave: {
        animation: '$skeleton-wave-loading 1s linear infinite',
        background: 'linear-gradient(90deg, #ddda 40%, #efefefaa, #ddda 60%) right / 300% 100%'
    },
    noAnimation: {
        background: '#D9D8D8'
    },
    '@keyframes skeleton-pulse-loading': {
        '0%': {
            backgroundColor: 'hsl(0, 0%, 85%)'
        },
        '100%': {
            backgroundColor: 'hsl(0, 0%, 95%)'
        }
    },
    '@keyframes skeleton-wave-loading': {
        to: {
            backgroundPosition: 'left'
        }
    },
    circle: {
        width: ({ width }: SkeletonProps) => width || '50px',
        height: ({ height }: SkeletonProps) => height || '50px',
        margin: ({ margin }: SkeletonProps) => margin || 0,
        borderRadius: '50%'
    },
    text: {
        width: ({ width }: SkeletonProps) => width || '100%',
        height: ({ height }: SkeletonProps) => height || '12px',
        margin: ({ margin }: SkeletonProps) => margin || 0,
        borderRadius: '.25rem'
    },
    rect: {
        width: ({ width }: SkeletonProps) => width || '100%',
        height: ({ height }: SkeletonProps) => height || '100px',
        margin: ({ margin }: SkeletonProps) => margin || 0,
        borderRadius: '.5rem'
    }
})

export default style
