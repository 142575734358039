import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import style from './style'
const useStyles = makeStyles(style)
import TableHead from './TableHead'
import TableBody from './TableBody'
import ColumnType from './Column.interface'
import Typography from '../Typography/Typography'

interface PropsInterface {
    caption?: string;
    columns: ColumnType[],
    data: Record<string, unknown>[];
    sortingCb?(field, order, tableData): Record<string, unknown>[];
    'data-testid'?: string;

}

type dataType = Record<string, unknown>[] | undefined

const defaultSorting = (sortField, sortOrder, tableData): dataType => {
    if (sortField) {
        const sorted = [...tableData].sort((a, b) => {
            if (a[sortField] === null) return 1
            if (b[sortField] === null) return -1
            if (a[sortField] === null && b[sortField] === null) return 0
            return (
                a[sortField].toString().localeCompare(b[sortField].toString(), 'en', {
                    numeric: true
                }) * (sortOrder === 'asc' ? 1 : -1)
            )
        })
        return sorted
    }
}
/**
 *
 */
const Table = ({
    caption,
    columns,
    data,
    sortingCb = defaultSorting,
    'data-testid': dataTestId = 'table'
}: PropsInterface): JSX.Element => {
    const styles = useStyles()

    const [tableData, setTableData] = useState(data)

    const handleSorting = (field, order) => {
        if (sortingCb) {
            const res = sortingCb(field, order, [...tableData])
            if (res) {
                setTableData(res)
            }
        }
    }

    return <table className={styles.table} data-testid={dataTestId}>
        {caption && <Typography variant='captionHeavy'>{caption}</Typography>}
        <TableHead columns={columns} handleSorting={handleSorting} data-testid={`${dataTestId}-head`}/>
        <TableBody columns={columns} data={tableData} data-testid={`${dataTestId}-body`}/>
    </table>
}

/**
 *
 */
export type TableType = PropsInterface

export default Table
