/**
 *
 */
const style = () => ({
    radio: {
        height: '24px',
        '& label': {
            cursor: 'pointer'
        },
        '& input[type="radio"]': {
            display: 'none'
        },
        '& .radio--icon-right': {
            marginLeft: '5px'
        },
        '& .radio--icon-left': {
            marginRight: '5px'
        }
    }
})

export default style
