import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import style from './style'
import Label from '../Label/Label'
import VerbalColorType from '../../helpers/types/VerbalColor.interface'
import CommonInput from '../CommonInput/CommonInput'
import CommonInputType from '../../helpers/types/CommonInput.interface'

const useStyles = makeStyles(style)

interface PropsInterface extends React.ComponentProps<'checkbox'>, CommonInputType {
    label?: string;
    color?: VerbalColorType;
    iconRight?: boolean;
}

/**
 *
 */
const Switch = ({
    disabled,
    label,
    children,
    color = 'primary',
    iconRight = false,
    onChange,
    checked,
    'data-testid': dataTestId = 'switch',
    ...props
}: PropsInterface): JSX.Element => {
    const styles = useStyles()
    const [isChecked, setIsChecked] = useState(checked)

    const handleOnChange = () => {
        if (!disabled) {
            if (onChange) onChange(!isChecked)
            setIsChecked(!isChecked)
        }
    }

    const iconWrapperClasses = [
        isChecked && `${color}`,
        (label || children) && (iconRight ? 'icon-right' : 'icon-left'),
        isChecked && 'active',
        disabled && 'disabled'
    ].filter((x) => x).map((x) => `switch--${x}`).join(' ')

    return (
        <CommonInput
            {...props}
            value={isChecked}
            clearable={false}
            data-testid={dataTestId}
        >
            <div className={styles.switch}>
                <Label disabled={disabled} className="switch--label" data-testid={`${dataTestId}-label`}>
                    {iconRight && (label || children)}
                    <div className={`switch--input-wrapper ${iconWrapperClasses}`}>
                        <input
                            type="checkbox"
                            checked={isChecked}
                            className="switch--input"
                            onChange={handleOnChange}
                            disabled={disabled}
                            data-testid={dataTestId}
                        />
                        <span className="switch--slider"></span>
                    </div>
                    { !iconRight && (label || children) }
                </Label>
            </div>
        </CommonInput>
    )
}

/**
 *
 */
export type SwitchType = PropsInterface

export default Switch
