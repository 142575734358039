/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import React from 'react'
import { makeStyles } from '@material-ui/core'
import CardDetails from './CardDetails'

interface CardDetailsProps {
    primaryCard: {
        id: string
        details?: {
            brand?: string
            last4?: string
            exp_month?: number | string
            exp_year?: number | string
        }
        status?: string
    }
}

/**
 *
 */
const style = (t) => {
    const { colors } = t['foundations-library']
    return {
        container: {
            margin: 'auto',
            width: '440px',
            height: '185px',
            borderRadius: '6px',
            padding: '20px',
            border: `1px solid ${colors.neutral[100]}`,
            '& .cardDetatils': {
                borderBottom: `1px solid ${colors.neutral[100]}`,
                paddingBottom: '20px'
            }
        }
    }
}
const useStyles = makeStyles(style)

/**
 *
 */
const PaymentMethodCard = ({
    primaryCard
}: CardDetailsProps): JSX.Element => {
    const classes = useStyles()

    return (
        <div className={classes.container}>
            <div className='cardDetatils'>
                <CardDetails primaryCard={primaryCard}/>
            </div>
        </div>
    )
}

/**
 *
 */
export { style }

export default PaymentMethodCard
