import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import style from './style'
import Notification from '../Notification/Notification'
import Icon from '../../Icon/Icon'
import TabInterface from './Tab.interface'

const useStyles = makeStyles(style)

const renderIcon = (icon, active, iconRight) => {
    if (!icon) return ''
    if (typeof icon === 'string') {
        return <Icon name={icon} size={20} color={active ? 'white' : 'primary-500'} className={`tab--icon ${iconRight && 'tab--icon-right'}`} data-testid='tab-icon'/>
    }

    return icon
}

// https://www.w3schools.com/howto/tryit.asp?filename=tryhow_js_tabs
/**
 *
 */
const Tab = ({
    text,
    children,
    active = false,
    notification,
    icon,
    iconRight = false,
    value,
    onClick,
    'data-testid': dataTestId = 'tab'
}: TabInterface): JSX.Element => {
    const styles = useStyles()

    const handleOnClick = () => {
        if (onClick) onClick(value)
    }

    const iconElement = renderIcon(icon, active, iconRight)
    const classes = [active && 'active'].filter((x) => x).map((x) => `${x}--tab`)
    return (
        <button className={`${styles.tab} ${classes}`} onClick={handleOnClick} data-testid={dataTestId}>
            {!iconRight && iconElement}
            {children || text}
            {iconRight && iconElement}
            {notification && <Notification
                className="tab--notification"
                color={active ? 'primary-300' : 'primary-400'}
                data-testid={`${dataTestId}-notification`}
            >{notification}</Notification>}
        </button>
    )
}

export default Tab
