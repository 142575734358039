import React from 'react'
import { makeStyles } from '@material-ui/core'

const jss = (t) => {
    const { colors, typography } = t['foundations-library']
    return {
        stepText: {
            display: 'inline-block  ',
            '&.step-text--neutral': {
                color: colors.neutral[500],
                '&.step-text--active': {
                    color: colors.secondary[500]
                }
            },
            '&.step-text--completed': {
                color: colors.primary[500]
            },
            '&.step-text--error': {
                color: colors.destructive[600]
            },
            '& .step-text--title': {
                ...typography.subtitle3
            },
            '& .step-text--subtitle': {
                ...typography.captionLight,
                whiteSpace: 'nowrap'
            }
        }
    }
}

const useStyles = makeStyles(jss)

interface PropsInterface {
    title?: string;
    subtitle?: string;
    active?: boolean;
    status: 'neutral' | 'error' | 'completed';
    className?: string;
    'data-testid'?: string;
}

/**
 *
 */
const StepText = ({
    title,
    subtitle,
    status = 'neutral',
    active,
    className,
    'data-testid': dataTestId = 'step-text'
}: PropsInterface): JSX.Element => {
    const styles = useStyles()
    const classes = [styles.stepText, `step-text--${status}`, active ? 'step-text--active' : '', className || ''].filter((x) => x).join(' ')
    return <div className={classes} data-testid={dataTestId}>
        {title ? <div className='step-text--title' data-testid={`${dataTestId}-title`}>{title}</div> : ''}
        {subtitle ? <div className='step-text--subtitle' data-testid={`${dataTestId}-subtitle`}>{subtitle}</div> : ''}
    </div>
}

export default StepText
