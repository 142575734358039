import React from 'react'
import { Typography, Button } from 'foundations-library/components'
import { makeStyles } from '@material-ui/core'

/**
 *
 */
const style = (t) => {
    const { colors } = t['foundations-library']
    return {
        container: {
            margin: 'auto',
            marginTop: '20px',
            width: '440px',
            height: '262px',
            borderRadius: '6px',
            padding: '20px',
            border: `1px solid ${colors.neutral[100]}`,
            '& .priceContainer': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: '8px'
            },
            '& .actionButtons': {
                display: 'flex',
                justifyContent: 'end',
                width: '100%',
                alignItems: 'flex-end',
                borderTop: `1px solid ${colors.neutral[100]}`,
                paddingTop: '16px'
            }
        }
    }
}

/**
 *
 */
export { style }

const useStyles = makeStyles(style)

interface PropsInterface {
    account?: any
    routeProps?: any
    setActiveTab(v): any;
}

/**
 *
 */
const PreviusStatement = ({
    account,
    routeProps,
    setActiveTab
}: PropsInterface): JSX.Element => {
    const classes = useStyles()
    const pushRoute = newTab => routeProps.history.push(`${routeProps.match.path}${newTab}`)
    return (
        <div className={classes.container}>
            <Typography
                data-testid='statement-title'
                variant='subtitle1'
                color='secondary-500'
                style={{ marginBottom: '16px' }}
            >
                Previous statement
            </Typography>

            <div className='priceContainer' data-testid='previous-balance-container'>
                <Typography data-testid='previous-balance-label' variant='subtitle3'>Previous balance:</Typography>
                <Typography data-testid='previous-balance-value' variant='body3'>{account?.ps_previous_balance || '-'}</Typography>
            </div>

            <div className='priceContainer' data-testid='payments-container'>
                <Typography data-testid='payments-label' variant='subtitle3'>Payments:</Typography>
                <Typography data-testid='payments-value' variant='body3'>{account?.ps_payments || '-'}</Typography>
            </div>

            <div className='priceContainer' data-testid='adjustments-container'>
                <Typography data-testid='adjustments-label' variant='subtitle3'>Adjustments:</Typography>
                <Typography data-testid='adjustments-value' variant='body3'>{account?.ps_adjustments || '-'}</Typography>
            </div>

            <div className='priceContainer' data-testid='recent-charges-container'>
                <Typography data-testid='recent-charges-label' variant='subtitle3'>Recent charges:</Typography>
                <Typography data-testid='recent-charges-value' variant='body3'>{account?.ps_current_charges || '-'}</Typography>
            </div>

            <div style={{ float: 'right' }}>
                <Button
                    data-testid='view-recent-charges-button'
                    color='primary'
                    variant='text'
                >
                </Button>
            </div>

            <div className='actionButtons'>
                <Button
                    data-testid='view-statements-button'
                    color='primary'
                    size='small'
                    variant='outlined'
                    icon='go'
                    onClick={() => { pushRoute('statements'); setActiveTab && setActiveTab('statements') }}
                >
                    View statements
                </Button>
            </div>
        </div>
    )
}

export default PreviusStatement
