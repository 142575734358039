import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FormModal',_vm._g(_vm._b({attrs:{"max-width":"500","data-test-id":"fwd-item-modal","discard_title":_vm.l.t('app.are-you-sure', 'Are you sure?')},on:{"close":function($event){_vm.show = false}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("\n      "+_vm._s(_vm.l.t('app.more-options', 'More options'))+"\n    ")]},proxy:true},{key:"buttons",fn:function(){return [_c('w-btn',{staticClass:"ml-5",attrs:{"color":"secondary"},on:{"click":function($event){_vm.show = false}}},[_vm._v(_vm._s(_vm.l.t('app.cancel', 'Cancel')))]),_vm._v(" "),_c('w-btn',{attrs:{"color":"primary","disabled":_vm.readonly},on:{"click":_vm.save_conf_changes}},[_vm._v(_vm._s(_vm.l.t('app.save-notifications-settings', 'Save notifications settings')))])]},proxy:true}]),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},'FormModal',_vm.$attrs,false),_vm.$listeners),[_vm._v(" "),_c('div',[_c('div',{staticClass:"d-flex"},[_c('w-switch',{attrs:{"label":_vm.l.t('forwarding-item.call-screening', 'Call screening'),"hide-details":"auto","data-test-id":"fwd-item-screening-switch"},model:{value:(_vm.screening),callback:function ($$v) {_vm.screening=$$v},expression:"screening"}}),_vm._v(" "),_c('InfoLink',{attrs:{"max-width":"300","top":"","no-carret":"","data-test-id":"call-screening-text","activator_classes":"mt-3"}},[_vm._v("\n          "+_vm._s(_vm.l.t(
            'live-answer.call-screening-text',
            'Call Screening provides you an audio "pre-screening" of an incoming call, telling you what number was called, what the caller\'s number is and giving you the option to accept or reject the call. The caller does not hear this message and continues to hear the ring.'
          ))+"\n        ")])],1),_vm._v(" "),_c(VDivider,{staticClass:"light-divider mt-1 mb-1"}),_vm._v(" "),_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"w-body-2 info--text text--darken-1 mr-2 my-auto"},[_vm._v("\n              "+_vm._s(_vm.l.t('forwarding-item.call-screening-caller-id', 'Caller ID to show me:'))+"\n            ")]),_vm._v(" "),_c('w-radio-group',{staticClass:"radio-group ml-n1",attrs:{"row":"","hide-details":"auto"},model:{value:(_vm.caller_id),callback:function ($$v) {_vm.caller_id=$$v},expression:"caller_id"}},[_c(VRadio,{attrs:{"label":_vm.l.t('app.calling-number', 'Calling number'),"color":"secondary","value":"calling_number","data-test-id":"fwd-item-calling-num-radio"}}),_vm._v(" "),_c(VRadio,{attrs:{"label":_vm.l.t('app.called-number', 'Called number'),"color":"secondary","value":"called_number","data-test-id":"fwd-item-called-num-radio"}})],1)],1),_vm._v(" "),_c('w-text-field',{staticClass:"w-100",attrs:{"placeholder":_vm.l.t('forwarding-item.voice-tag', 'Add a voice tag'),"type":"text","maxlength":"40","hide-details":"auto","data-test-id":"fwd-item-voice-tag-input"},model:{value:(_vm.voice_tag),callback:function ($$v) {_vm.voice_tag=$$v},expression:"voice_tag"}}),_vm._v(" "),(_vm.forwarding_type === 'extension')?_c('w-select',{staticClass:"w-100 mt-5",attrs:{"placeholderl":_vm.l.t('forwarding-item.ringing-style', 'Ringing style'),"items":[
                {value: 'DEFAULT', text: _vm.l.t('app.default-ring-style', 'Default ring style')},
                {value: 'STYLE_2', text: _vm.l.t('forwarding-item.ring-style {}', 'Ring style {}', [2]) },
                {value: 'STYLE_3', text: _vm.l.t('forwarding-item.ring-style {}', 'Ring style {}', [3]) },
                {value: 'STYLE_4', text: _vm.l.t('forwarding-item.ring-style {}', 'Ring style {}', [4]) },
                {value: 'STYLE_5', text: _vm.l.t('forwarding-item.ring-style {}', 'Ring style {}', [5]) },
                {value: 'STYLE_6', text: _vm.l.t('forwarding-item.ring-style {}', 'Ring style {}', [6]) },
                {value: 'STYLE_7', text: _vm.l.t('forwarding-item.ring-style {}', 'Ring style {}', [7]) },
                {value: 'STYLE_8', text: _vm.l.t('forwarding-item.ring-style {}', 'Ring style {}', [8]) },
                {value: 'STYLE_9', text: _vm.l.t('forwarding-item.ring-style {}', 'Ring style {}', [9]) } ],"hide-details":"auto","data-test-id":"fwd-item-distinctive-ring-select"},model:{value:(_vm.distinctive_ring),callback:function ($$v) {_vm.distinctive_ring=$$v},expression:"distinctive_ring"}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }