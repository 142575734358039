import React, { useState } from 'react'
import { IconButton, Menu } from 'foundations-library/components'
import { Skeleton } from 'foundations-library/components'

interface PropsInterface {
    item?: any,
    disabled?: boolean;
    loading?: boolean;
    options: any;
    'data-testid'?: string;
}

/**
 *
 */
const Actions = ({
    item,
    disabled = false,
    options,
    loading,
    'data-testid': dataTestId
}: PropsInterface): JSX.Element => {
    const [isOpen, setIsOpen] = useState(false)

    const onMenuChange = (v) => {
        if (!item) return null
        const option = options.find((x) => x.value === v)
        if (option) {
            option.cb(item.statement_id)
        }
        setIsOpen(false)
        return true
    }
    return <>
        {loading && <Skeleton variant='circle' height='36px' width='36px' data-testid={`${dataTestId}-loader`}/>}
        {!loading && <>
            <IconButton
                disabled={disabled}
                color='neutral'
                name='more-vert'
                variant='icon'
                onClick={() => setIsOpen(!isOpen)}
                data-testid={`${dataTestId}`}
            />
            <Menu
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                onChange={onMenuChange}
                options={options}
                data-testid={`${dataTestId}-menu`}
            />
        </>}
    </>
}

export default Actions
