type Animation = 'spin' | 'spin-forward' | 'spin-backward'

const classes = {
    spin: 'spin-forward',
    'spin-forward': 'spin-forward',
    'spin-backward': 'spin-backward'
}

/**
 *
 */
const getAnimationClass = (animation) => {
    return classes[animation]
}

/**
 *
 */
export type AnimationType = Animation

/**
 *
 */
export {
    getAnimationClass
}
