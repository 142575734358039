<template>
    <w-loader v-if="menu.loading" size="small"/>
    <div v-else id="menu-component">
        <w-alert
            v-if="menu.alert"
            :message="menu.alert.message"
            :level="menu.alert.level"
            :closable="menu.enable_close_alert"
            @closed="menu.alert = null"
            class="mb-6"
        />
        <w-form 
            @submit.prevent="on_submit"
            @changed="may_submit = true"
            @on_close_error="alert = null"
            :readonly="readonly"
            :error_message="alert"
            class="menu-settings"
            data-discard="true"
            data-test-id="menus-form"
        >
            <div v-if="full_menu" class="w-80 mx-auto">
                <FormInput :label="l.t('app.name', 'Name')">
                    <w-text-field v-if="readonly" v-model="menu.item.name" :rules="[$validation.required()]" not-clearable hide-details='auto' maxLength="30" data-test-id="menus-name-input"/>
                    <w-text-field v-else v-model="menu.item.name" :rules="[$validation.required()]" hide-details='auto' maxLength="30" data-test-id="menus-name-input"/>
                </FormInput>
                <FormInput :label="l.t('app.outgoing-message', 'Outgoing message')">
                    <SelectorWithCreate
                      v-model="menu.item['greeting']"
                      :return_object="true"
                      :rules="[$validation.required()]"
                      mode="greetings"
                      data-test-id="menus-greeting-select"
                    />
                </FormInput>
                <FormInput :label="l.t('menus.invalid-entry-message', 'Invalid entry message')">
                    <SelectorWithCreate
                        v-model="menu.item.keypress_error"
                        :return_object="true"
                        :before_list="[{value: null, text: l.t('app.none', 'None')}]"
                        mode="greetings"
                        data-test-id="menus-invalid-select-selector"
                    />
                </FormInput>
                <FormInput :label="l.t('menus.keypress-wait-time', 'Wait time')">
                    <w-select v-model="menu.item.keypress_wait_time" :items="[1, 2, 3, 4, 5].map((x) => ({value: x, text: `${x}s`}))" hide-details="auto" data-test-id="menus-keypress-select"/>
                </FormInput>
                <FormInput :label="l.t('menus.allow-extension-dial', 'Allow extension dial')">
                    <w-radio-group
                      v-model="menu.item.allow_extension_dial"
                      row
                      hide-details='auto'
                    >
                      <v-radio
                        :label="l.t('app.yes', 'Yes')"
                        :value="true"
                        color="#6E7A82"
                        data-test-id="menu-allow-extension-dial-yes-radio"
                      ></v-radio>
                      <v-radio
                        :label="l.t('app.no', 'No')"
                        :value="false"
                        color="#6E7A82"
                        data-test-id="menu-allow-extension-dial-no-radio"
                      ></v-radio>
                    </w-radio-group>
                </FormInput>
            </div>
            <v-simple-table class="mt-13 without-hover-effect" data-test-id="menu-selection-action">
                <thead data-test-id="table-head">
                    <tr data-test-id="table-head-row">
                        <th class="pl-0" data-test-id="selection">{{ l.t('menus.selection', 'Selection') }}</th>
                        <th class="w-100" data-test-id="action">{{ l.t('app.action', 'Action') }}</th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="key of ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '#']">
                        <MenuRow
                            :button="key"
                            :active="menu.buttons[key]"
                            :description="menu.analysis[key].description || l.t('menus.no-rules-set', 'No rules set')"
                            :opened="opened_options.includes(key)"
                            :is_preset="!!menu.item.options[key].name"
                            :key="`${key}-${menu.buttons[key]}-${opened_options.includes(key)}`"
                            :readonly="readonly"
                            @button_click="toggle_option(key)"
                            @apply_preset="apply_preset(key, $event)"
                            @create_preset="create_preset(key, $event)"
                            @clear="clear_actions(key)"
                            @toggle="toggle_option(key)"
                            :data-test-id="key"
                        />
                        <tr v-if="opened_options.includes(key)" data-test-id="opened-menu">
                            <td colspan="5" class="pa-0 py-3" :key="`${menu.item.options[key].validation_error}`" data-test-id="opened-menu-td">
                                <FormValidationError
                                    v-if="menu.item.options[key].validation_error"
                                    @closed="error_closed(key)"
                                    class="mt-2 mb-5"
                                >
                                    {{menu.item.options[key].validation_error}}
                                </FormValidationError>
                                <APIAutocomplete
                                    v-if="menu.item.options[key] && menu.item.options[key].id && menu.item.options[key].name"
                                    v-model="menu.item.options[key]"
                                    :return_object="true"
                                    mode="presets"
                                    :readonly="readonly"
                                    :key="`is-this-a-preset-${menu.item.options[key].name}-selecor`"
                                />
                                <RouteConfiguration
                                    v-else
                                    class="page-content"
                                    @changed="menu.item.options[key] = $event"
                                    :_route="menu.item.options[key]"
                                    :data-test-id="`menus-route-conf-${key}`"
                                    :readonly="readonly"
                                    :key="`is-this-a-preset-${menu.item.options[key].name}`"
                                />
                            </td>
                        </tr>
                    </template>
                    <MenuRow
                        button="*"
                        :active="menu.buttons_no_input"
                        :description="menu.analysis['*'].description || l.t('menus.no-rules-set', 'No rules set')"
                        :opened="opened_options.includes('*')"
                        :is_preset="!!menu.item.timeout_handler.name"
                        :key="`*-${menu.buttons_no_input}-${opened_options.includes('*')}`"
                        :readonly="readonly"
                        @button_click="toggle_option('*')"
                        @apply_preset="apply_preset('*', $event)"
                        @create_preset="create_preset('*', $event)"
                        @clear="clear_actions('*')"
                        @toggle="toggle_option('*')"
                        data-test-id="menu-option-*"
                    />
                    <tr v-if="opened_options.includes('*')" data-test-id="menu-topion-*-opened">
                        <td colspan="5" class="pa-0 py-3" data-test-id="opened-menu-*">
                            <FormValidationError
                                v-if="menu.item.timeout_handler.validation_error"
                                @closed="error_closed('*')"
                                class="mt-5 mb-5"
                            >
                                {{menu.item.timeout_handler.validation_error}}
                            </FormValidationError>
                            <APIAutocomplete
                                v-if="menu.item.timeout_handler && menu.item.timeout_handler.id && menu.item.timeout_handler.name"
                                v-model="menu.item.timeout_handler"
                                :return_object="true"
                                mode="presets"
                                :readonly="readonly"
                                :key="`is-this-a-preset-${menu.item.timeout_handler.name}-selecor`"
                            />
                            <RouteConfiguration
                                v-else
                                class="page-content"
                                @changed="menu.item.timeout_handler = $event"
                                :_route="menu.item.timeout_handler"
                                :readonly="readonly"
                                data-test-id="menus-route-conf-timeout_handler"
                                :key="`is-this-a-preset-${menu.item.timeout_handler.name}`"
                            />
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
            <div class="d-flex justify-center mt-5">
                <CancelChanges @click:cancel="$emit('click:cancel')"/>
                <w-btn :disabled="readonly || !may_submit" color="primary" type="submit" class="ml-5" data-test-id="menus-save-btn">
                    {{ l.t("menu.save", "Save menu") }}
                </w-btn>
            </div>
        </w-form>
    </div>
</template>

<script>
    import {vueComponent, emptyRouteRule} from 'helpers';

    import l from '../../libs/lang';
    import Menu from '../../models/Menu';
    import RouteAnalyzer from '../../models/RouteAnalyzer';
    import FormValidation from '../../libs/validation/form-rules';
    import MenuRow from './MenuRow.vue';
    import FormInput from '../elements/form/FormInput.vue';
    import CancelChanges from '../elements/CancelChanges.vue';
    import APIAutocomplete from '../elements/form/APIAutocomplete.vue';
    import SelectorWithCreate from '../elements/SelectorWithCreate.vue';
    import FormValidationError from '../elements/form/FormValidationError.vue';
    import RouteConfiguration from '../elements/routes/RouteConfiguration.vue';

  export default {
    props: ['id', '_menu', 'mode', 'full_menu', 'readonly'],
    components: {
    MenuRow,
    FormInput,
    CancelChanges,
    APIAutocomplete,
    SelectorWithCreate,
    RouteConfiguration,
    FormValidationError,
},
    data() {
      return {
        l,
        preset: null,
        opened_options: [],
        may_submit: false,
        menu: new Menu(this.$session, vueComponent(this), this.$props.full_menu),
        alert: null,
      };
    },
    async created() {
      if (this.$props._menu) {
        this.$data.menu.item = this.$data.menu.prepare_menu_data(this.$props._menu);
      } else if (this.$props.id) {
        const item = await this.$data.menu.getItem(this.$props.id);
        this.$data.menu.item = this.$data.menu.prepare_menu_data(item);
      } else {
        this.$data.menu.item = this.$data.menu.prepare_menu_data(this.$data.menu.item);
      }
    },
    methods: {
        async on_submit() {
            if (!this.validate()) {
                setTimeout(() => {
                    const alerts = document.querySelectorAll('.form-validation-error');
                    if (alerts.length) {
                        const element = alerts[0];
                        const headerOffset = 150;
                        const elementPosition = element.getBoundingClientRect().top;
                        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                        window.scrollTo({
                            top: offsetPosition,
                            behavior: 'smooth',
                        });
                    }
                }, 5);
                return false;
            }
            await this.$data.menu.save();
            return true;
        },
        check_readonly(key) {
            if ((key !== '*' && this.$data.menu.item.options[key].id) || (key === '*' && this.$data.menu.item.timeout_handler)) {
                return true;
            } else {
                return !this.$props.readonly;
            }
        },
        validate_option(route) {
            let has_all_calls = FormValidation.all_calls_required(route)();
            if (has_all_calls !== true) {
                route.validation_error = has_all_calls;
                route.rules.push(emptyRouteRule());
                this.$forceUpdate();
            }
            const fwd_call_has_items = FormValidation.forward_call_has_destination(route)()
            if (fwd_call_has_items !== true) {
                route.validation_error = route.validation_error || fwd_call_has_items;
            }
            
            this.$forceUpdate();
            return route.validation_error || true
        },
        validate() {
            let errors = [];
            for (const key in this.$data.menu.item.options) {
                if (this.$data.menu.item.options[key] && this.$data.menu.buttons[key]) {
                    errors = [this.validate_option(this.$data.menu.item.options[key])]
                }
            }
            if (this.$data.menu.buttons_no_input) {
                errors.push(this.validate_option(this.$data.menu.item.timeout_handler))
            }
            errors = errors.filter((err) => typeof err === 'string')
            this.$data.alert = errors[0]
            if (errors.length) {
                for (const key in this.$data.menu.item.options) {
                    if (this.$data.menu.item.options[key]) delete this.$data.menu.item.options[key].validation_error;
                }
                delete this.$data.menu.item.timeout_handler.validation_error;
            }
            return !errors.length;
        },
        error_closed(key) {
            if (key === '*') {
                this.$data.menu.item.timeout_handler.validation_error = null;
            } else {
                this.$data.menu.item.options[key].validation_error = null;
            }
            this.$forceUpdate();
        },
        toggle_option(key) {
            if (this.check_readonly(key)) {
                const index = this.$data.opened_options.indexOf(key);
                if (index > -1) {
                    this.$data.opened_options.splice(index, 1);
                } else {
                    this.$data.opened_options.push(key);
                }
                if (key === '*') {
                    if (!this.$data.menu.buttons_no_input) {
                        this.$data.menu.buttons_no_input = true;
                    }
                } else if (!this.$data.menu.buttons[key]) {
                    this.$data.menu.buttons[key] = true;
                }
            }
        },
        clear_actions(key) {
            if (key === '*') {
                this.$data.menu.buttons_no_input = false;
            } else {
                this.$data.menu.buttons[key] = false;
            }
            const index = this.$data.opened_options.indexOf(key);
            if (index > -1) {
                this.$data.opened_options.splice(index, 1);
            }
        },
        apply_preset(key, data) {
            if (!data) return null;
            if (key === '*') {
                this.$data.menu.item.timeout_handler = data;
                this.$data.menu.buttons_no_input = true;
            } else {
                this.$data.menu.item.options[key] = data;
                this.$data.menu.buttons[key] = true;
            }
            this.$data.menu.analysis[key] = new RouteAnalyzer(data);

            return true;
        },
        async create_preset(key, data) {
            if (!data) return null;
            let route = null;
            if (key === '*') {
                this.$data.menu.item.timeout_handler.name = data;
                this.$data.menu.item.timeout_handler.newPreset = true;
                route = {...this.$data.menu.item.timeout_handler};
                this.$data.menu.buttons_no_input = true;
            } else {
                this.$data.menu.item.options[key].name = data;
                this.$data.menu.item.options[key].newPreset = true;
                this.$data.menu.buttons[key] = true;
                route = {...this.$data.menu.item.options[key]};
            }
            this.$data.menu.analysis[key] = new RouteAnalyzer(route);

            return true;
        },
        // uses_regular_route(key, route) {
        //     this.$data.menu.analysis[key] = new RouteAnalyzer(route);
        // }
    },
  };
</script>
