
<template>
  <div class="rule-action-action">
    <template v-if="action.action === 'forward'">
      <div class="w-subtitle-3 font-weight-600 mt-2 mb-4">
        {{ l.t('app.fwd-calls-to-phone-ext', 'Forward calls to the following phone numbers or extensions') }}
      </div>
      <ForwardingItem
        v-for="(item, k) in action.items"
        :key="`action-items-${k}-${fwd_item_key}--${action.items.lenght}`"
        :usedExtensions="usedExtensions(k)"
        :value="action.items[k]"
        @changed="onExtensionChange(k, $event)"
        :required="true"
        :closable="action.items.length > 1"
        :readonly="readonly"
        :device_extension="extension && extension.device_membership ? extension : undefined"
        @closed="delete_fwd_item(rule_index, action_index, k)"
        data-test-id="route-conf-rule-fwd-item"
        class="w-100"
      />
      <w-btn
        v-if="!readonly"
        @click="add_destination"
        text
        :ripple="false"
        color="primary"
        class="mt-3 pl-0 mb-6"
        data-test-id="route-conf-add-to-list"
      >
        <v-icon color="primary">mdi-plus-circle</v-icon>{{ l.t('app.add-another-destination', 'Add another destination') }}
      </w-btn>
      <RingingDuration
        name="ringingtime"
        v-model="action.timeout"
        :seconds="[25, 30, 45, 60]"
        :placeholder="l.t('forwarding.timeout', 'Ringing time')"
        update:search-input
        class="mb-2"
        hide-details="auto"
        data-test-id="`route-conf-rule-timeout-input`"
      />
      <APIAutocomplete
        v-model="action.hold_music"
        :return_object="true"
        :before_list="[{value: null, text: l.t('hold-music.default', 'Default ringtone')}]"
        mode="hold_music"
        :key="`hold_music-${action.hold_music ? action.hold_music.id : action_index}`"
        data-test-id="route-conf-rule-hold-music-select"
        :placeholder="l.t('app.hold-music', 'Hold music')"
      />
    </template>
    <ExtensionSelector
      v-else-if="['voicemail', 'fax'].includes(action.action)"
      :return_object="true"
      v-model="action.extension"
      :rules="[$validation.required()]"
      :options_list="!readonly ? ['notifications'] : []"
      :placeholder="l.t('app.select-an-extension', 'Select an extension')"
      :full_width="true"
      :key="action.action"
      data-test-id="route-conf-rule-extension-selector"
    />
    <template v-else-if="action.action === 'hold'">
      <APIAutocomplete
        v-model="action.hold_music"
        :return_object="true"
        :before_list="[{value: null, text: l.t('hold-music.default', 'Default ringtone')}]"
        mode="hold_music"
        :key="`hold__music-${action.hold_music ? action.hold_music.id : action_index}`"
        data-test-id="route-conf-rule-default-ringtone-selector"
      />
      <RingingDuration
        v-model="action.duration"
        :seconds="[24, 25, 30, 45, 60, 75, 90]"
        :placeholder="l.t('app.duration', 'Duration')"
        class="mt-3"
        :data-test-id="`route-conf-rule-${rule_index}-duration-input`"
      />
    </template>
    <SelectorWithCreate
      v-else-if="action.action === 'greeting'"
      v-model="action.greeting"
      :return_object="true"
      :rules="[$validation.required()]"
      mode="greetings"
      data-test-id="route-conf-rule-greeting-selector"
    />
    <SelectorWithCreate
      v-else-if="action.action === 'menu'"
      :value="action.menu"
      :rules="[$validation.required()]"
      mode="menus"
      :key="`menus-${action.menu ? action.menu.id : `${action_index}-${!!action.menu}`}`"
      @input="on_selector_change('menu', $event)"
      data-test-id="route-conf-rule-menus-selector"
    />
    <SelectorWithCreate
      v-else-if="action.action === 'queue'"
      :value="action.queue"
      :rules="[$validation.required()]"
      mode="queues"
      :key="`queues-${action.queue ? action.queue.id : action_index}`"
      @input="on_selector_change('queue', $event)"
      data-test-id="route-conf-rule-queues-selector"
    />
    <SelectorWithCreate
      v-else-if="action.action === 'live_answer'"
      :value="action.script"
      mode="live_answer"
      :rules="[$validation.required(), enabled_script]"
      :key="`live-answer-${action.script ? action.script.id : `${action_index}-${!!action.script}`}`"
      @input="on_selector_change('script', $event)"
      data-test-id="route-conf-rule-lr-selector"
    />
    <APIAutocomplete
      v-else-if="action.action === 'trunk'"
      v-model="action.trunk"
      :return_object="true"
      :rules="[$validation.required()]"
      mode="trunks"
      :required="true"
      :key="`trunks-${action.trunk ? action.trunk.id : action_index}`"
      data-test-id="route-conf-rule-trunks-selector"
    />
    <span v-else></span>
  </div>
</template>

<script>
  import l from '../../../libs/lang';
  import ForwardingItem from './ForwardingItem.vue';
  import DefaultModal from '../modal/DefaultModal.vue';
  import RingingDuration from '../form/RingingDuration.vue';
  import APIAutocomplete from '../form/APIAutocomplete.vue';
  import SelectorWithCreate from '../SelectorWithCreate.vue';
  import ExtensionSelector from '../../fragments/ExtensionSelector.vue';
  
  const generate_fwd_item_key = () => `fwd-item-key-${Date.now()}`
  export default {
    name: 'RuleActionAction',
    props: {
      action: {
        type: Object,
        required: true,
      },
      route: {
        type: Object,
        required: true,
      },
      action_index: {
        type: Number,
        required: true,
      },
      rule_index: {
        type: Number,
        required: true,
      },
      extension: {
        type: Object,
        required: false,
      },
      readonly: {
        type: Boolean,
        default: false
      }
    },
    components: {
      DefaultModal,
      ForwardingItem,
      APIAutocomplete,
      RingingDuration,
      ExtensionSelector,
      SelectorWithCreate,
    },
    data() {
      return {
        l,
        fwd_item_key: generate_fwd_item_key()
      };
    },
    methods: {
      onExtensionChange(k, data) {
        this.action.items[k] = data.value
        this.$forceUpdate();
      },
      usedExtensions(k) {
        const usedExtensions = {};
        for (let i = 0; i < this.$props.action.items.length; i++) {
          if (
            i !== k
            && this.$props.action.items[i]
            && this.$props.action.items[i].extension
            && this.$props.action.items[i].extension.id
            && !usedExtensions[this.$props.action.items[i].extension.id]
            && (
              k > i
              || !this.$props.action.items[k].extension
              || (this.$props.action.items[k].extension && this.$props.action.items[i].extension.id !== this.$props.action.items[k].extension.id)
            )
          ) {
              usedExtensions[this.$props.action.items[i].extension.id] = i;
          }
        }
        return Object.keys(usedExtensions).map((x) => Number.parseInt(x));
      },
      on_selector_change(mode, data) {
        this.action[mode] = data;
        this.$forceUpdate();
      },
      add_destination() {
        this.$props.route.rules[this.$props.rule_index].actions[this.$props.action_index].items = this.$props.route.rules[this.$props.rule_index].actions[this.$props.action_index].items || [];
        this.$props.route.rules[this.$props.rule_index].actions[this.$props.action_index].items.push({ number: '' });
        this.$data.fwd_item_key = generate_fwd_item_key()
      },
      delete_fwd_item(rule_index, action_index, item_index) {
        if (
          this.$props.route.rules[rule_index]
          && this.$props.route.rules[rule_index].actions
          && this.$props.route.rules[rule_index].actions[action_index]
          && this.$props.route.rules[rule_index].actions[action_index].items
          && this.$props.route.rules[rule_index].actions[action_index].items.length
        ) {
          this.$props.route.rules[this.$props.rule_index].actions[this.$props.action_index].items.splice(item_index, 1);
          this.$data.fwd_item_key = generate_fwd_item_key()
        }
      },
      enabled_script(v) {
        if (v && this.$props?.action?.script?.id === v && !this.$props.action.script.enabled) {
          return this.$lang.t('live-answer.script-must-be-enabled', 'Script must be enabled')
        }
        return true
      }
    },
  };
</script>
