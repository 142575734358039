import React from 'react'
import { TextField } from 'foundations-library/components'
import { TextFieldType } from 'foundations-library/types'

interface ZipCodeTextFieldProps extends TextFieldType {
    countryCode: string,
}

/**
 * Addaptation of zip-code-text-field - uses new components and interfaces
 *
 * Textfield component that provides format-as-you type functionality for entering a zip
 * or postal code (depending on the specified country)
 */
const ZipCodeTextField = ({ countryCode, onChange, ...props } : ZipCodeTextFieldProps) : JSX.Element => {
    const liveFormatPostalCode = (postalCode: string) => {
        postalCode = postalCode.replace(/[^a-zA-Z\d\s:]/g, '')
        let formatedPostalCode = ''
        let isLastNumeric = true
        Array.from(postalCode).forEach(char => {
            if (formatedPostalCode.length === 7) return
            if (isLastNumeric) {
                if (/^\d+$/.test(char)) return
            } else {
                if (/^[a-zA-Z]+$/.test(char)) return
            }
            if (formatedPostalCode.length === 3) formatedPostalCode += ' '
            if (char === ' ') return
            formatedPostalCode += char.toUpperCase()
            if (formatedPostalCode.length === 3 && window.prevPostalCode?.length === 2) formatedPostalCode += ' '
            isLastNumeric = !isLastNumeric
        })
        window.prevPostalCode = formatedPostalCode
        return formatedPostalCode
    }

    const onTextFieldChange = v => {
        if (!onChange) return null
        let formatted = ''
        if (countryCode === 'CA') {
            formatted = liveFormatPostalCode(v).slice(0, 7)
        } else {
            formatted = v.replace(/\D/g, '').slice(0, 5)
        }
        onChange(formatted)
    }

    return (
        <TextField
            {...props}
            placeholder={countryCode === 'CA' ? 'Postal code' : 'Zip code'}
            maxLength={countryCode === 'CA' ? 7 : 5}
            onChange={onTextFieldChange}
        />
    )
}

export default ZipCodeTextField
