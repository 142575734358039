import React from 'react'
import { makeStyles } from '@material-ui/core'
import style from './style'
import VerbalColorType from '../../helpers/types/VerbalColor.interface'

const useStyles = makeStyles(style)
interface PropsInterface {
    color?: VerbalColorType,
    marginTop?: number | string;
    orientation?: 'horizontal' | 'vertical';
    marginBottom?: number | string;
    marginRight?: number | string;
    marginLeft?: number | string;
    height?: string | number;
    width?: string | number;
    'data-testid'?: string;
}

const getProperCssProp = (val) => {
    if (typeof val === 'string') return val
    if (val || val === 0) return `${val}px`
    return undefined
}

/**
 *
 */
const Divider = ({
    color = 'neutral-100',
    orientation = 'horizontal',
    marginTop,
    marginBottom,
    marginRight,
    marginLeft,
    height,
    width,
    'data-testid': dataTestId = 'divider'
}: PropsInterface): JSX.Element => {
    const styles = useStyles()
    const elStyle = {
        marginTop: getProperCssProp(marginTop),
        marginBottom: getProperCssProp(marginBottom),
        marginRight: getProperCssProp(marginRight),
        marginLeft: getProperCssProp(marginLeft)
    }
    if (orientation === 'horizontal') {
        elStyle.width = getProperCssProp(width)
        elStyle.borderWidth = getProperCssProp(height)
    } else {
        elStyle.borderWidth = getProperCssProp(width)
        elStyle.height = getProperCssProp(height)
    }

    const inlineStyle = {}
    for (const key of Object.keys(elStyle)) {
        if (elStyle[key]) inlineStyle[key] = elStyle[key]
    }
    return <div
        className={`${styles.divider}
        divider--${color}
        divider--${orientation}`}
        style={inlineStyle}
        data-testid={dataTestId}
    />
}

export default Divider
