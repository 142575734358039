const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
]

/**
 *
 */
const generateResultAlertDownloadAllPdf = (results) => {
    const successes = results.filter((x) => x.level === 'success')
    const errors = results.filter((x) => x.level === 'error')
    if (!successes.length) {
        return {
            level: 'error',
            message: 'Something went wrong'
        }
    }
    if (!errors.length) {
        return {
            level: 'success',
            message: 'All documents downloaded successfully'
        }
    }
    return {
        level: 'success',
        message: `${successes.length} files downloaded successfully. ${errors.length} files download failed`
    }
}

/**
 *
 */
const formatDate = (str) => { // 09/21/2024
    const dateParts = str.split('/')

    return `${months[Number.parseInt(dateParts[0]) - 1]} ${dateParts[1]}, ${dateParts[2]}`
}

/**
 *
 */
export {
    months,
    generateResultAlertDownloadAllPdf,
    formatDate
}
