import React from 'react'
import { makeStyles } from '@material-ui/core'
import Icon from '../Icon/Icon'

const jss = (t) => {
    const { colors, typography } = t['foundations-library']
    return {
        stepperIcon: {
            height: '44px',
            width: '44px',
            display: 'inline-flex',
            alignItems: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            justifyContent: 'space-around',
            '& .standard-step-icon': {
                borderRadius: '50%',
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '44px',
                '&.stepper-icon--neutral': {
                    ...typography.body1,
                    display: 'inline-flex',
                    width: '30px',
                    height: '30px',
                    backgroundColor: colors.neutral[50],
                    color: colors.neutral[500],
                    '&.stepper-icon--active': {
                        ...typography.h8,
                        display: 'inline-flex',
                        width: '44px',
                        height: '44px',
                        backgroundColor: colors.secondary[400],
                        color: 'white'
                    }
                }
            }
        }
    }
}

const useStyles = makeStyles(jss)

interface PropsInterface {
    status: 'neutral' | 'error' | 'completed';
    text?: string;
    active?: boolean;
    className?: string;
    'data-testid'?: string;
}

const renderIcon = (status, active, props, datatestId = 'step-icon', text = '#') => {
    const dataTestId = `${datatestId}-${status}`
    const activeClassName = active ? 'stepper-icon--active' : ''
    if (status === 'completed') {
        return <Icon
            name='check-alt'
            size={active ? 44 : 30}
            className={activeClassName}
            colors={['primary-400', 'white']}
            data-testid={dataTestId}
            {...props}
        />
    }

    if (status === 'error') {
        return <Icon
            name='notification-alert'
            className={activeClassName}
            size={active ? 44 : 30}
            colors={['destructive-50', 'destructive-600']}
            data-testid={dataTestId}
            {...props}
        />
    }
    return <div
        className={`standard-step-icon stepper-icon--neutral ${activeClassName} ${props.className || ''}`}
        data-testid={dataTestId}
    >{text}</div>
}

/**
 *
 */
const StepperIcon = ({
    status = 'neutral',
    active,
    text = '#',
    'data-testid': dataTestId,
    ...props
}: PropsInterface): JSX.Element => {
    const styles = useStyles()
    return <div className={styles.stepperIcon} data-testid={dataTestId}>
        {renderIcon(status, active, props, dataTestId, text)}
    </div>
}

export default StepperIcon
