export default /**
eeeeeeeeeeeeeee *
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee */
() => ({
    payNow: {
        marginTop: '50px',
        maxWidth: '900px',
        margin: 'auto'
    },
    tabs: {
        marginBottom: '30px'
    }
})
