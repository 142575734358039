import React from 'react'
import { makeStyles } from '@material-ui/core'
import jss from './style'
import VerbalColorType from '../../helpers/types/VerbalColor.interface'
import CommonInput from '../CommonInput/CommonInput'
import CommonInputType from '../../helpers/types/CommonInput.interface'
import Radio from '../Radio/Radio'
import Typography from '../Typography/Typography'

const useStyles = makeStyles(jss)

type ValueType = string | number

type ItemType = {
    value: ValueType;
    label?: string;
    color?: VerbalColorType;
    disabled?: boolean;
}

interface PropsInterface extends Omit<CommonInputType, 'value'>{
    items: ItemType[],
    onChange(v): any;
    value?: ValueType;
    disabled?: boolean;
    color?: VerbalColorType;
    title?: string;
    vertical?: boolean;
    align?: 'start' | 'center' | 'end';
}

/**
 *
 */
const RadioGroup = ({
    items = [],
    disabled,
    title,
    color = 'primary',
    onChange,
    vertical = false,
    value,
    align = 'start',
    'data-testid': dataTestId = 'radio-group',
    ...props
}: PropsInterface): JSX.Element => {
    const styles = useStyles({ align })
    const classes = [
        styles.radioGroup,
        vertical ? styles.radioVertical : styles.radioHorizontal,
        !vertical && `radio-group--align-${align}`
    ].filter((x) => x).join(' ')

    const handleOnChange = (v) => {
        if (disabled) return null
        if (v !== value && onChange) onChange(v)
    }

    return (<>
        { title ? <Typography variant='subtitle2' className={styles.title}>{title}</Typography> : '' }
        <CommonInput
            value={value}
            clearable={false}
            data-testid={dataTestId}
            {...props}
        >
            <div className={classes} data-testid={dataTestId}
            >
                {
                    items && items.length && items.map(
                        (item, i) => <Radio
                            color={color}
                            {...item}
                            className='radio-group-item'
                            disabled={item.disabled || disabled}
                            checked={value === item.value}
                            onChange={() => handleOnChange(item.value)}
                            key={`radio-group-item-${i}`}
                            data-testid={`radio-group-item-${i}`}
                        />
                    )
                }
            </div>

        </CommonInput>
    </>)
}

export default RadioGroup
