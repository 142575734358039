<template>
    <div>
        <w-switch
            v-model="enable_notifications"
            :label="label"
            hide-details='auto'
            data-test-id="enable-notifications-switch"
        ></w-switch>
        <div v-if="enable_notifications">
          <h4 class="w-body-2 my-2">
              {{ l.t('app.notify-by-sms', "Notify by SMS") }}
          </h4>
          <div>
              <Tel
                  :value="val.sms"
                  @changed="val.sms = $event.clean_number"
                  :validation="true"
                  data-test-id="notifications-tel-input"
              />
          </div>
          <h4
              class="w-body-2"
              :class="{
                  'my-5': val && val.emails && val.emails.length,
                  'mt-5':((!val.emails) || (val.emails && !val.emails.length)),
              }"
          >
              {{ l.t('app.notify-by-email', "Notify by email") }}
          </h4>
          <Email
              v-for="(email, i) in val.emails"
              @closed="on_close_email(i)"
              :required="true"
              v-model="val.emails[i]"
              :key="`${i}-${val.emails.length}`"
              :closable="true"
              :class="i+1 < val.emails.length ? 'mb-2' : ''"
              :data-test-id="`email-notification-${i}`"
          />
          <AddToListButton
              @click="on_add_email_click"
              :disabled="!val.emails.every((x) => x)"
              left
              data-test-id="add-email-notifications-btn"
          >
              {{ l.t('app.email-add', "Add email address") }}
          </AddToListButton>
        </div>
    </div>
</template>
<script>
  import l from '../../libs/lang';
  import Tel from './Tel.vue';
  import Email from './Email.vue';
  import AddToListButton from './AddToListButton.vue';

  export default {
    props: {
        value: {
            required: false,
        },
        label: {
            type: String,
            default: l.t('app.enable', 'Enable'),
        }
    },
    components: {Tel, Email, AddToListButton},
    data() {
      return {
        l,
        val: null,
        enable_notifications: false,
      };
    },
    created() {
        this.$data.val = this.$props.value;
        this.$data.enable_notifications = !!this.$data.val;
        if (this.$data.val) {
            if (!this.$data.val.sms) this.$data.val.sms = '';
            if (!this.$data.val.emails) this.$data.val.emails = [];
        }
    },
    methods: {
        on_add_email_click() {
            if (!this.$data.val.emails) this.$data.val.emails = [];
            this.$data.val = {
                ...this.$data.val,
                emails: [...this.$data.val.emails, '']
            }
        },
        on_close_email(i) {
            this.$data.val.emails.splice(i, 1);
        },
    },
    watch: {
        enable_notifications(v) {
            if (!v) {
                this.$emit('input', null);
            } else {
                if (!this.$data.val) {
                    this.$data.val = {
                        sms: '',
                        emails: [],
                    };
                }
                this.$emit('input', this.$data.val);
            }
        },
        val: {
            deep: true,
            handler(v) {
                this.$emit('input', v);
            }
        }
    }
  };
</script>
