import React from 'react'
import { Skeleton } from 'foundations-library/components'
/**
 *
 */
const ListLoader = (): JSX.Element => {
    return <div data-testid='statements-loader'>
        <div style={{ marginBottom: '30px', height: '36px' }}>
            <Skeleton variant='rect' style={{ float: 'right' }} height={36} width={110} data-testid='statements-loader-button'/>
        </div>
        <div data-testid='statements-items'>
            {[1, 2, 3].map((x) => <Skeleton variant='rect' style={{ marginBottom: '15px' }} height={62} width='100%' key={`list-loader-p-${x}`} data-testid={`statements-items-${x}`}/>)}
        </div>
    </div>
}

export default ListLoader
