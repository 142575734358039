import React, { useState, useCallback, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import iconButtonStyle from './style'
import Icon from '../Icon/Icon'
import IconNameType from '../../helpers/types/IconName.interface'
import { ThemeContext } from 'providers'

interface PropsInterface extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'name'> {
  name: IconNameType;
  size?: 'small' | 'medium' | 'large';
  color?: 'primary' | 'secondary' | 'tertiary' | 'neutral' | 'destructive';
  variant?: 'contained' | 'contained-light' | 'icon';
  onHover?(e): any;
  'data-testid'?: string;
}

const iconSizes = {
    small: 18,
    medium: 20,
    large: 24
}

const shades = {
    contained: {
        inactive: [400, 0], // circle, icon
        hover: [500, 0],
        active: [600, 0],
        disabled: [100, 0]
    },
    'contained-light': {
        inactive: [50, 400],
        hover: [100, 400],
        active: [200, 500],
        disabled: [50, 200]

    },
    icon: {
        inactive: ['transparent', 400],
        hover: [50, 400],
        active: [100, 500], // same here
        disabled: [0, 200]
    }
}

const useStyles = makeStyles(iconButtonStyle)
const renderIcon = (icon, btnSize, color) => {
    if (!icon) return ''
    if (typeof icon === 'string') {
        return <Icon className='button-icon__icon' size={iconSizes[btnSize]} color={color} name={icon}/>
    }

    return icon
}

const getShade = (colors, color, shade) => {
    if (shade === 'transparent') return shade
    if (shade && color && colors[color]) {
        return colors[color][shade]
    }
    return '#FFFFFF'
}

/**
 *
 */
const IconButton: React.FC<PropsInterface> = ({
    name,
    size = 'medium',
    variant = 'contained',
    color = 'primary',
    style = {},
    'data-testid': dataTestId = 'icon-button',
    ...props
}): JSX.Element => {
    const themeContext = useContext(ThemeContext)
    const { colors } = themeContext?.['foundations-library'] ?? {}

    const styles = useStyles()

    const getDefaultShade = useCallback((index: 0 | 1) => {
        if (props.disabled) {
            return getShade(colors, 'neutral', shades[variant].disabled[index])
        }
        return getShade(colors, color, shades[variant].inactive[index])
    }, [props.disabled, variant])

    const [buttonColor, setButtonColor] = useState(getDefaultShade(0))
    const [iconColor, setIconColor] = useState(getDefaultShade(1))
    const [isMouseOver, setIsMouseOver] = useState(false)

    const updateStyles = useCallback((type: 'active' | 'inactive' | 'hover', mouseUp = false) => {
        if (mouseUp && isMouseOver) {
            setButtonColor(getShade(colors, color, shades[variant].hover[0]))
            setIconColor(getShade(colors, color, shades[variant].hover[1]))
        } else {
            setButtonColor(getShade(colors, color, shades[variant][type][0]))
            setIconColor(getShade(colors, color, shades[variant][type][1]))
        }
    }, [isMouseOver])

    const onMouseDown = (e) => {
        updateStyles('active')
        if (props.onMouseDown) props.onMouseDown(e)
    }
    const onMouseUp = (e) => {
        updateStyles('inactive', true)
        if (props.onMouseUp) props.onMouseUp(e)
        if (props.onClick) props.onClick(e)
    }
    const onMouseEnter = (e) => {
        updateStyles('hover')
        if (props.onMouseEnter) props.onMouseEnter(e)
        setIsMouseOver(true)
    }
    const onMouseLeave = (e) => {
        updateStyles('inactive')
        if (props.onMouseLeave) props.onMouseLeave(e)
        setIsMouseOver(false)
    }

    let classes = [color, size, variant].filter((x) => x).map((x) => `button--${x}`).join(' ')
    if (props.className) classes = `${classes} ${props.className}`

    return <button
        {...props}
        className={`${styles.iconButton} ${classes}`}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={{ background: buttonColor, ...style }}
        onClick={() => undefined}
        data-testid={dataTestId}
    >
        {renderIcon(name, size, iconColor)}
    </button>
}
/**
 *
 */
export type IconButtonType = PropsInterface
export default IconButton
