import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import style from './style'
import Icon from '../Icon/Icon'
import IconNameType from '../../helpers/types/IconName.interface'
interface PropsInterface extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: JSX.Element | IconNameType; // can be passed as JSX or icon prop
  size?: 'small' | 'medium' | 'large';
  color?: 'primary' | 'secondary' | 'tertiary' | 'neutral' | 'destructive' | 'white';
  variant?: 'contained' | 'outlined' | 'text';
  iconLeft?: boolean;
  'data-testid'?: string;
}

const useStyles = makeStyles(style)

const iconSizes = {
    small: 18,
    medium: 22,
    large: 22
}
const renderIcon = (icon, btnSize, iconLeft) => {
    if (!icon) return ''
    if (typeof icon === 'string') {
        return <Icon name={icon} size={iconSizes[btnSize]} color='inherit' className={`button__icon ${iconLeft && 'button__icon-left'}`} data-testid='button-icon'/>
    }

    return icon
}

/**
 *
 */
const Button: React.FC<PropsInterface> = ({
    size = 'medium',
    variant = 'contained',
    color = 'primary',
    icon,
    iconLeft = false,
    children,
    ...props
}): JSX.Element => {
    const styles = useStyles()
    const onMouseDown = (e) => e.target.classList.add('button--active')
    const onMouseUp = (e) => e.target.classList.remove('button--active')

    const iconElement = renderIcon(icon, size, iconLeft)
    let iconClass = ''
    if (icon && iconLeft) {
        iconClass = 'has-icon-left'
    } else if (icon) {
        iconClass = 'has-icon-right'
    }

    let classes = [color, size, iconClass, variant, iconClass].filter((x) => x).map((x) => `button--${x}`).join(' ')
    if (props.className) classes = `${classes} ${props.className}`
    return <button
        {...props}
        className={`${styles.button} ${classes}`}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
    >
        { iconLeft && iconElement }
        { children }
        { !iconLeft && iconElement }
    </button>
}

/**
 *
 */
export type ButtonType = PropsInterface

export default Button
