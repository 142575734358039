import { formatPhoneNumber as formatNumber, formatAsYouType } from 'phone-numbers'
import Countries from 'countries'

/**
 *
 * @param {number} duration
 * @returns
 */
const formatDuration = (duration) => {
    if (duration === 0) return '00:00:00'
    let hours = 0
    if (!duration || duration === Infinity || duration === -Infinity) return '—'
    let min = Math.floor(duration / 60)
    if (min >= 60) {
        hours = Math.floor(min / 60)
        min %= 60
    }
    const sec = (Math.round(duration % 60)).toFixed(1)
    return `${hours < 10 ? `0${hours}` : hours}:${min < 10 ? `0${min}` : min}:${sec < 10 ? '0' : ''}${sec}`
}

/**
 *
 * @param {string} name
 * @returns
 */
const getFirstAndLastName = (name) => {
    const white_space_index = name.indexOf(' ')
    let first_name = ''
    let last_name = ''
    if (white_space_index > -1) {
        const regex = /[^a-zA-Z0-9_ '-]/g
        first_name = name.substring(0, white_space_index).replace(regex, '')
        last_name = name.substring(white_space_index).replace(regex, '')
    } else {
        first_name = name
        last_name = name
    }

    return { first_name: first_name.trim(), last_name: last_name.trim() }
}

/**
 *
 * @param {string} country_code
 * @returns
 */
const countryName = function (country_code) {
    if (!country_code) {
        return '—'
    }

    const country = Countries.find((x) => x.alpha2 === country_code)
    if (country) return country.name

    return '—'
}

/**
 *
 * @param {object} data
 * @returns
 */
const stringifyAddress = (data) => {
    if (!data) return '—'
    let lines = data.line_1 || ''
    if (data.line_2) lines += ` ${data.line_2}`
    lines = lines.trim()
    const parts = [
        lines,
        data.postal_code,
        data.city,
        data.province,
        countryName(data.country)
    ].filter((x) => x)
    if (parts.length) return parts.join(', ')

    return '—'
}

/**
 *
 * @param {number} timestamp
 * @returns
 */
const formatTime = function (timestamp) {
    if (!timestamp || timestamp === -Infinity || timestamp === Infinity) return '—'
    if (Date.parse(timestamp)) return (new Date(Date.parse(timestamp))).toLocaleString()

    return (new Date(timestamp * 1000)).toLocaleString()
}

/**
 *
 * @param {number} val
 * @returns
 */
const formatDate = function (val) {
    if (!val) return null
    const date = new Date(val * 1000)
    return date.toLocaleString('en-US', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'
    })
}

/**
 *
 * @param {number} val
 * @param {string} prefix
 * @returns
 */
const formatPrice = (val, prefix) => {
    if (!val && val !== 0) {
        return '—'
    }
    const num = Number.parseInt(val)
    if (Number.isNaN(num)) {
        return '—'
    }
    const value = (num / 100).toFixed(2)
    if (prefix) {
        return `${prefix}${value}`
    }
    return value
}

/**
 *
 * @param {object} device
 * @returns
 */
const formatDeviceModelName = (device) => {
    if (!device) return '—'
    if (typeof device !== 'object') return '—'

    return `${device.manufacturer || ''} ${device.name || ''}`.trim()
}

/**
 *
 * @param {string} phoneNumber
 * @param {string} country alpha2 code
 * @returns
 */
const formatPhoneNumber = (phoneNumber, country) => {
    if (!phoneNumber) return ''
    if (phoneNumber.startsWith('+88351000')) { // iNum
        return `${phoneNumber.slice(0, 4)} (${phoneNumber.slice(4, 8)}) ${phoneNumber.slice(8)}`
    }
    return formatNumber(phoneNumber, country)
}

/**
 *
 * @param {string} number
 * @param {string} country alpha2 code
 * @returns
 */
const formatPhoneNumberAsYouType = (number, country = 'US') => {
    const phone_number = number
    if (!phone_number) return ''
    if (phone_number.startsWith('+88351000')) { // iNum
        return `${phone_number.slice(0, 4)} (${phone_number.slice(4, 8)}) ${phone_number.slice(8)}`
    }

    let formatted = formatAsYouType(phone_number, country)
    if (country === 'US' && formatted.length && formatted[0] !== '+') {
        formatted = `+1 ${formatted}`
    }
    return formatted
}
/**
 *
 * @param {string} number
 * @param {boolean} no_code
 * @param {string} text_value
 * @param {number} text_start
 * @returns
 */
const formatPhoneNumberWithText = (number, no_code, text_value, text_start) => {
    let phone_number = number
    if (!phone_number) return ''
    if (!text_value) return formatPhoneNumber(number)
    phone_number = `${phone_number.slice(
        0,
        text_start
    )}${text_value.toUpperCase()}${phone_number.slice(
        text_start + text_value.length
    )}`
    const r = /^(\+1)([0-9]{0,3})([0-9]{0,3})([0-9]{0,3})([0-9]{0,4})([A-Z]+)([0-9]{0,3})([0-9]{0,3})([0-9]{0,4})$/
    let p = phone_number.match(r)
    if (p) {
        p = p
            .slice(no_code ? 2 : 1)
            .filter((v) => v.length)
        if (p.length) {
            let formatted_value = `${p[0]}`
            if (p[1]) {
                formatted_value += ` (${p[1]})`
            }
            if (p[2]) {
                formatted_value += ` ${p.slice(2).join('-')}`
            }
            return formatted_value.trim()
        }
    }
    return formatPhoneNumber(number)
}

/**
 *
 * @param {object} data
 * @returns
 */
const assemble_display_name = (data) => {
    const default_empty_value = '—'
    if (!data) return default_empty_value
    const name = []
    for (const key of ['prefix', 'first_name', 'middle_name', 'last_name', 'suffix']) {
        if (data[key]) name.push(data[key].trim())
    }
    if (name.length) return name.join(' ')
    if (data.nickname) return data.nickname.trim()

    if (data.company) return data.company.trim()

    return default_empty_value
}

/**
 *
 * @param {string} str
 * @returns
 */
const upperFirstCase = (str) => {
    if (!str) return ''

    if (typeof str !== 'string') {
        str = str.toString()
    }
    let first_char_index = str.length - str.trimStart().length
    if (first_char_index < 0) first_char_index = 0

    return str.slice(0, first_char_index + 1).toUpperCase() + str.slice(first_char_index + 1)
}

/**
 *
 */
export {
    formatDuration,
    getFirstAndLastName,
    countryName,
    stringifyAddress,
    formatTime,
    formatPrice,
    formatDeviceModelName,
    formatDate,
    formatPhoneNumber,
    formatPhoneNumberAsYouType,
    formatPhoneNumberWithText,
    assemble_display_name,
    upperFirstCase
}
