import { getVerbalColors } from 'helpers'

/**
 *
 */
const style = (t) => {
    const { colors, typography } = t['foundations-library']
    const verbalColors = getVerbalColors(colors)

    const coloring = {}
    for (const key of Object.keys(verbalColors)) {
        coloring[`&.badge--bg-${key}`] = {
            backgroundColor: verbalColors[key]
        }
        coloring[`&.badge--text-${key}`] = {
            color: verbalColors[key]
        }
    }

    for (const c of Object.keys(colors)) {
        coloring[`&.badge--${c}`] = {
            backgroundColor: verbalColors[`${c}-50`],
            color: verbalColors[`${c}-600`]
        }
    }
    return {
        badge: {
            textAlign: 'center',
            borderRadius: '7px',
            ...typography.buttonMedium,
            height: '32px',
            padding: '8px 14px 9px 14px',
            '&.badge--small': {
                ...typography.buttonSmall,
                height: '26px',
                padding: '5px 12px 6px 12px'
            },
            '&.badge--large': {
                ...typography.buttonLarge,
                height: '36px',
                padding: '9px 20px 9px 20px'
            },
            ...coloring
        }
    }
}

export default style
