import React from 'react'
import { ContentLoader } from 'foundations-library/components'
import { makeStyles } from '@material-ui/core'
import { ContentFactoryInterface } from './ContentLoaderFactory'

const jss = () => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        '& .loader-circle:not(:first-child)': {
            marginLeft: '20px'
        }
    }
})

const useStyles = makeStyles(jss)

interface PropsInterface extends ContentFactoryInterface {
    radius?: number;
}

const circles = (n, radius, dataTestId) => {
    const svgHeight = radius * 2
    const els = [<ContentLoader
        className='loader-circle'
        height={svgHeight}
        width={svgHeight}
        key='button-rect-0'
        data-testid={`${dataTestId}-svg-0`}
    ><circle cx="50%" cy="50%" r={radius}/></ContentLoader>]
    for (let i = 1; i < n; i++) {
        els.push(<ContentLoader
            className='loader-circle'
            height={svgHeight}
            width={svgHeight}
            key={`button-rect-${i}`}
            data-testid={`${dataTestId}-svg-${i}`}
        ><circle cx="50%" cy="50%" r={radius}/></ContentLoader>)
    }

    return els
}

/**
 *
 */

/**
 *
 */
const Circle = ({
    times = 1,
    radius = 30,
    'data-testid': dataTestId = 'circle-content-loader',
    ...props
}: PropsInterface): JSX.Element => {
    const styles = useStyles()
    const style = props.style || {}
    if (times === 1) {
        style.display = 'unset'
    }
    return <span className={styles.container} style={style} data-testid={dataTestId}>
        {circles(times, radius, dataTestId)}
    </span>
}

export default Circle
