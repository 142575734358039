import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import style from './style'
import Tab from './Tab/Tab'
import TabInterface from './Tab/Tab.interface'
import Typography from '../Typography/Typography'
import Button from '../Button/Button'
import Divider from '../Divider/Divider'

const useStyles = makeStyles(style)

interface PropsInterface {
    items: Omit<TabInterface, 'active' | 'onClick'>[];
    value?: any;
    onChange(v): any;
    textual?: boolean;
    className?: string;
    'data-testid'?: string;
}

/**
 *
 */
const Tabs = ({
    items,
    value,
    onChange,
    textual = false,
    className = '',
    'data-testid': dataTestId = 'tabs'
}: PropsInterface): JSX.Element => {
    const styles = useStyles()
    const handleOnClick = (v) => {
        if (onChange) onChange(v)
    }

    const tabs = useMemo(() => {
        const tabsItems: JSX.Element[] = []
        const getActiveItemIndex = () => {
            const index = items.findIndex((x) => Object.prototype.hasOwnProperty.call(x, 'value') && x.value === value)
            if (index > -1) return index

            handleOnClick(items[0].value)
            return 0
        }
        const activeItemIndex = getActiveItemIndex()
        items.map((x, i) => {
            const key = `tab-${i}`
            if (textual) {
                let el
                if (i === activeItemIndex) {
                    el = <Typography
                        variant='subtitle2'
                        tag='span'
                        key={key}
                        data-testid={`${dataTestId}-${i}`}
                    >{x.text ? x.text : x.children}</Typography>
                } else {
                    el = <Button
                        onClick={ () => handleOnClick(x.value)}
                        variant='text'
                        icon={x.icon}
                        iconRight={x.iconRight}
                        key={key}
                        data-testid={`${dataTestId}-${i}`}
                    >{x.text ? x.text : x.children}</Button>
                }
                tabsItems.push(el)
                if (i < items.length - 1) {
                    tabsItems.push(<Divider color='neutral-200' height='12px' orientation='vertical' key={`${key}-divider`}/>)
                }
            } else {
                tabsItems.push(<Tab
                    {...x}
                    active={i === activeItemIndex}
                    onClick={handleOnClick}
                    data-testid={`${dataTestId}-${i}`}
                    key={key}
                />)
            }
            return true
        })
        return tabsItems
    }, [items, value])

    const classes = [styles.tabs, textual ? 'tabs--textual' : '', className || ''].filter((x) => x).join(' ')
    return (
        <div className={classes} data-testid={dataTestId}>
            {tabs}
        </div>
    )
}

/**
 *
 */
export type TabsType = PropsInterface

export default Tabs
