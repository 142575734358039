/**
 *
 */
const styles = () => ({
    iconButton: {
        alignItems: 'center',
        transition: 'background-color 50ms linear',
        '&.button--small': {
            height: '32px',
            width: '32px',
            borderRadius: '18px'
        },
        '&.button--medium': {
            height: '36px',
            width: '36px',
            borderRadius: '18px'
        },
        '&.button--large': {
            height: '44px',
            width: '44px',
            borderRadius: '26px'
        }
    }
})

export default styles
