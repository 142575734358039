/* eslint-disable @typescript-eslint/naming-convention */

import React, { useState, useMemo } from 'react'
import PaymentMethod from 'console/src/models/PaymentMethod'
import { PhoenixSessionContext } from 'providers'
import { emptyAddress } from 'helpers'
import TabsSubmenu from '../../elements/TabsSubmenu'
import { makeStyles } from '@material-ui/core'
import style from './style'
import StripeFields from '../../elements/StripeFields'
import Contact from '../../elements/Contact'
import { Divider, Alert, Checkbox } from 'foundations-library/components'
import { FormButtons } from 'functional-foundations'

const useStyles = makeStyles(style)

const additionalOptions = {
    first_name: '',
    last_name: '',
    address: {
        line_1: '',
        line_2: '',
        city: '',
        province: '',
        postal_code: '',
        country: 'US'
    }
}

interface PropsInterface {
    afterCreateCb(v): any;
    onCancel?(): any;
    onChangeTab?(v): any;
}

const emptyContact = () => ({ first_name: '', last_name: '', address: emptyAddress() })

/**
 *
 */
const create = ({
    onCancel,
    afterCreateCb,
    onChangeTab
}: PropsInterface): JSX.Element => {
    const sessionContext = React.useContext(PhoenixSessionContext)
    const pMethod = useMemo(() => new PaymentMethod(sessionContext.session, null), [])

    const [disabled, setDisabled] = useState(false)
    const [setAsPrimary, setSetAsPrimary] = useState(false)
    const [item, setItem] = useState({
        account_id: null,
        status: null,
        nickname: '',
        type: 'cc',
        cc_token: null
    })
    const [contact, setContact] = useState(emptyContact())
    const [contactIsValid, setContactIsValid] = useState(false)
    const [cardDataIsValid, setCardDataIsValid] = useState(false)
    const [resetFields, setResetFields] = useState(false)
    const [alert, setAlert] = useState(null)
    const [elements, setElements] = useState({
        number: {
            el: null,
            stripe_el: 'cardNumber',
            id: '#stripe-card-number',
            error: null,
            is_complete: false,
            conf: {
                placeholder: 'Card number'
            }
        },
        exp: {
            el: null,
            stripe_el: 'cardExpiry',
            id: '#stripe-card-exp',
            error: null,
            is_complete: false
        },
        cvc: {
            el: null,
            stripe_el: 'cardCvc',
            id: '#stripe-card-cvc',
            error: null,
            is_complete: false
        }
    })
    const styles = useStyles()

    const clearFields = () => {
        setContact(emptyContact())
        setResetFields(true)
        setItem({
            ...item,
            nickname: ''
        })
    }

    const generateAdditionalOptions = () => {
        const options = {
            name: `${additionalOptions.first_name} ${additionalOptions.last_name}`
        }
        for (const [a_key, value] of Object.entries(additionalOptions.address)) {
            let key
            if (a_key === 'province') {
                key = 'state'
            } else if (a_key === 'postal_code') {
                key = 'zip'
            } else {
                key = a_key.replace('_', '')
            }
            options[`address_${key}`] = value || ''
        }
        return options
    }

    const onSubmit = async () => {
        setDisabled(true)
        const options = generateAdditionalOptions()
        const newPMethod = await pMethod.create(elements.number.el, options, setAsPrimary, item.nickname)
        setDisabled(false)
        if (pMethod.alert.level === 'success') {
            clearFields()
            if (afterCreateCb) afterCreateCb(newPMethod)
        }
        setAlert(pMethod.alert)
    }

    return (
        <div className={styles.create} data-testid='payment-methods-create'>
            {
                onChangeTab &&
                <TabsSubmenu
                    value='create'
                    onChange={(v) => v === 'list' && onChangeTab('list')}
                    data-testid='payment-methods-create-tabs'
                />
            }
            {alert && <Alert level={alert.level} marginY={30} onClose={() => setAlert(null)} autocloseAfter={5000}>{alert.message}</Alert>}
            <form onSubmit={onSubmit}>
                <StripeFields
                    pMethod={pMethod}
                    isValid={setCardDataIsValid}
                    disabled={disabled}
                    resetInputs={resetFields}
                    inputsReset={ () => setResetFields(false) }
                    elements={elements}
                    setElements={setElements}
                />
                <Divider />
                <Contact value={contact} onChange={setContact} isValid={setContactIsValid} disabled={disabled}/>
                <Checkbox
                    variant='neutral'
                    label='Save this card for future transactions'
                    checked={setAsPrimary}
                    value={true}
                    onChange={setSetAsPrimary}
                    disabled={disabled}
                    data-testid="cc-set-as-primary"
                />
                <FormButtons
                    onCancel={() => onCancel && onCancel()}
                    disableConfirm={!contactIsValid || !cardDataIsValid || pMethod.processing_card}
                    onConfirm={onSubmit}
                    confirmText={'Continue'}
                />
            </form>
        </div>
    )
}

export default create
