import ajax from 'ajax'
import PhoneComUser from 'phone-com-user'
import { getPhoneCom } from 'phonecom'
class API {
// BlockList
static getBlocklist = async (cursor) => {
    const phonecom = await getPhoneCom()
    const requestUrl = `${PhoneComUser.getv5ApiRoot()}/accounts/get-blocklist`
    return ajax
        .post(requestUrl, { account_id: phonecom.voip_id, cursor: cursor, limit: 5 })
        .then((response) => response.data)
}

static removeFromBlocklist = async (item_id) => {
    const phonecom = await getPhoneCom()
    const requestUrl = `${PhoneComUser.getv5ApiRoot()}/accounts/remove-from-blocklist`
    return ajax
        .post(requestUrl, { account_id: phonecom.voip_id, blocklist_id: item_id })
        .then((response) => response.data)
}

static addToBlocklist = async (pattern) => {
    const phonecom = getPhoneCom()
    const requestUrl = `${PhoneComUser.getv5ApiRoot()}/accounts/add-to-blocklist`
    return ajax
        .post(requestUrl, {
            account_id: phonecom.voip_id,
            pattern: pattern,
            block_type: 'incoming',
            note: ''
        })
        .then((response) => {
            if (response.errors) {
                return {
                    status: 'fail',
                    message: response.errors[Object.keys(response.errors)[0]]
                }
            }
            return response.data
        })
}

// Teammates
static loadUsers = async (page_size, offset) => {
    const account_id = PhoneComUser.getAPIAccountId()
    const api_base = process.env.REACT_APP_USER_API_URL || ''
    const requestUrl = `${api_base}/voip/${account_id}/users?page_size=${page_size}&offset=${offset}&order_by=status_asc_first_name_asc.asc&include=extension,plan,devices,numbers`
    const response = await ajax.get(requestUrl)
    if (response.message === 'Network Error') return []
    return response.data.data
}

static getUser = async (userId) => {
    const account_id = PhoneComUser.getAPIAccountId()
    const api_base = process.env.REACT_APP_USER_API_URL || ''
    const requestUrl = `${api_base}/voip/${account_id}/users?page_size=1&offset=0&order_by=status.asc&include=extension,plan,devices,numbers&user_ids=${userId}`
    const response = await ajax.get(requestUrl)
    if (response.message === 'Network Error') return null
    return response.data.data[0]
}
}

export default API
