import React, { PropsWithChildren } from 'react'
import { makeStyles } from '@material-ui/core'
import jss from './style'
import SimpleColor from '../../helpers/types/SimpleColor.interface'

const useStyles = makeStyles(jss)

interface PropsInterface {
    top?: boolean;
    bottom?: boolean;
    right?: boolean;
    left?: boolean;
    position?: 'top' | 'bottom' | 'right' | 'left';
    color?: SimpleColor;
    value: string;
    'data-testid'?: string;
}

/**
 *
 */
const Infotip = ({
    top,
    bottom,
    right,
    left,
    position = 'right',
    color = 'neutral',
    children,
    value,
    'data-testid': dataTestId = 'infotip'

}: PropsWithChildren<PropsInterface>): JSX.Element => {
    const styles = useStyles()
    let tPosition = position
    if (top) {
        tPosition = 'top'
    } else if (bottom) {
        tPosition = 'bottom'
    } else if (left) {
        tPosition = 'left'
    } else if (right) {
        tPosition = 'right'
    }

    return <div className={styles.infotip} data-testid={dataTestId}>
        {children}
        {value && <span className={`infotip infotip--${tPosition} infotip--${color}`} data-testid={`${dataTestId}-popup`}>
            {value}
        </span>}
    </div>
}

export default Infotip
