const displays = [
    'inline',
    'block',
    'contents',
    'flex',
    'grid',
    'inline-block',
    'inline-flex',
    'inline-grid',
    'inline-table',
    'list-item',
    'run-in',
    'table',
    'table-caption',
    'table-column-group',
    'table-header-group',
    'table-footer-group',
    'table-row-group',
    'table-cell',
    'table-column',
    'table-row',
    'none',
    'initial',
    'inherit'
]

const aligns = [
    'start',
    'end',
    'left',
    'right',
    'center',
    'justify',
    'justify-all',
    'match-parent',
    'inherit',
    'initial',
    'revert',
    'revert-layer',
    'unset'
]

/**
 *
 */
export {
    displays,
    aligns
}
