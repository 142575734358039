import React from 'react'
import { Tabs } from 'foundations-library/components'
import { makeStyles } from '@material-ui/core'

const style = () => ({
    'tabs-submenu': {
        marginBottom: '22px',
        marginTop: '20px'
    }
})

const useStyles = makeStyles(style)

interface PropsInterface {
    value: 'list' | 'create';
    onChange(v): any;
}
/**
 *
 */
const TabsSubmenu = (props: PropsInterface): JSX.Element => {
    const style = useStyles()

    return <Tabs
        data-testid='payment-methods-tabs-submenu'
        items={[
            {
                text: 'Saved cards',
                value: 'list'
            },
            {
                text: 'Add a new card',
                value: 'create'
            }
        ]}
        textual
        className={style['tabs-submenu']}
        {...props}
    />
}

export default TabsSubmenu
