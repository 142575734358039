import { get_account_information, use_subaccount, use_main_account } from 'phoenix-session-helpers'
import l from '../libs/lang'
import Resource from './Resource'
import CSVTransformer from '../libs/csv-helpers/CSVTransformer'

/**
 *
 */
export default class Media extends Resource {
    /**
     *
     * @param {object} session
     * @param {string} type
     * @param {object} component
     */
    constructor (session, type, component) {
        super(session, component, '/media')
        this.selector_mode = ['hold_music', 'greetings']
        this.type = type
        this.uri = '/media'
        this.filters = {
            name: null,
            pattern: 'contains'
        }
        this.item = {
            type: 'greeting',
            origin: 'tts',
            tts: {
                voice: 'Joanna / English (US)',
                text: ''
            }
        }
        this.accounts = []
        this.screen = 'accounts-list'
        this.session_main_account_cache_key = 'media-loader_main_session_account_user_id'
        this.loading_message = null
        this.headers = {
            account_id: 'account_id',
            subaccount: 'subaccount',
            account_name: 'account_name',
            media_id: 'media_id',
            media_name: 'media_name',
            media_type: 'media_type',
            origin: 'origin'
        }
        /* eslint-disable */
        this.functions = {
            account_id: (account) => account.id,
            subaccount: (account) => !account.main,
            account_name: (account) => account.name,
            media_id: (account, media) => media.id,
            media_name: (account, media) => media.name,
            media_type: (account, media) => media.type,
            origin: (account, media) => media.origin,
        };
        this.importing_functions = {
            account_id: (v) => parseInt(v),
            subaccount: (v) => v === 'true',
            account_name: (v) => v,
            media_id: (v) => parseInt(v),
            media_name: (v) => v,
            media_type: (v) => (v === 'hold_music' ? l.t('app.music-on-hold', 'Music on hold') : l.t('app.greeting', 'Greeting')),
            origin: (v) => v,
        };
		/* eslint-enable */
        this.is_subaccount = !!this.session.user.is_subaccount
        this.csv_media = null
        this.checkAllAccounts = true
        this.checkAllItems = true
        this.updating_in_progress = false
        this.stop_the_process = false
    }

    /**
     *
     */
    async listAccountsAndSubaccounts () {
        this.loading = true
        try {
            this.main = JSON.parse(JSON.stringify(this.session.user.account))
            this.main.main = !this.is_subaccount
            this.accounts.push(this.main)
            if (this.accounts[0].features['subaccounts-enabled']) {
                const subaccounts = await this.session.get_list_all('/subaccounts')
                this.accounts = this.accounts.concat(subaccounts.items)
            }
            this.selectAllAccounts()
            for (const account of this.accounts) {
                account.items = []
            }
        } catch (err) {
            this.validation_error(err)
        }
        this.loading = false
    }

    /**
     *
     */
    selectAllAccounts () {
        for (const account of this.accounts) {
            account.selected = true
        }
    }

    /**
     *
     */
    deselectAllAccounts () {
        for (const account of this.accounts) {
            account.selected = false
        }
    }

    /**
     *
     */
    async apply_filters () {
        this.loading = true
        try {
            for (const account of this.accounts) {
                account.items = []
                if (account.selected) {
                    account.items = await this.findMediaItems(account)
                }
            }
            this.selectAllItems()
            this.screen = 'media-list'
        } catch (err) {
            this.validation_error(err)
            this.useOriginalAccount()
        }
        this.loading = false
    }

    /**
     *
     */
    selectAllItems () {
        for (const account of this.accounts) {
            account.items = account.items.map((x) => {
                x.selected = true
                return x
            })
        }
        return true
    }

    /**
     *
     */
    deselectAllItems () {
        for (const account of this.accounts) {
            account.items = account.items.map((x) => {
                x.selected = false
                return x
            })
        }
        return true
    }

    /**
     *
     * @param {object} account
     * @returns
     */
    async findMediaItems (account) {
        const filters = []
        this.filters.pattern = this.filters.pattern || 'contains'
        if (this.filters.pattern !== 'all') filters.push(`filters[name][]=${this.filters.pattern}:${this.filters.name}`)

        filters.push('filters[ownership]=user')
        const url = `/media?${filters.join('&')}`
        try {
            if (!account.main) this.session = use_subaccount(this.session, account)
            const items = await this.session.get_list_all(url)

            this.useOriginalAccount()
            return items.items
        } catch (err) {
            const error_msg = err.message ? err.message : l.t('media-loader.loading-error', 'Error loading media for your account with the id of {}', [account.id])
            const index = this.accounts.findIndex((x) => x.id === account.id && x.selected)
            this.accounts[index].error_loading_items = error_msg

            this.useOriginalAccount()

            return []
        }
    }

    /**
     *
     */
    show_update_form () {
        try {
            this.filterSelectedItems()
            const greetings = this.takeMediaByType('greeting')
            const moh = this.takeMediaByType('hold_music')
            const type = greetings.length < moh.length ? 'greeting' : 'hold_music'
            const media = greetings.concat(moh)
            this.item = {
                type,
                origin: Media.findMostFrequestValue(media, 'origin'),
                tts: {
                    voice: 'Joanna / English (US)',
                    text: ''
                }
            }
            if (type === 'hold_music') {
                this.item.tts_voice = this.item.tts.voice
                this.item.tts_text = this.item.tts.text
            }
            this.screen = 'update-media'
        } catch (err) {
            this.useOriginalAccount()
            this.validation_error(err)
        }
    }

    /**
     *
     */
    filterSelectedItems () {
        for (const account of this.accounts) {
            account.items = account.items.filter((x) => x.selected)
        }
    }

    /**
     *
     * @param {string} type
     * @returns
     */
    takeMediaByType (type) {
        let media = []
        for (const account of this.accounts) {
            media = media.concat(account.items.filter((x) => x.type === type))
        }

        return media
    }

    /**
     *
     * @param {Array} arrayOfObjects
     * @param {string} prop
     * @returns
     */
    static findMostFrequestValue (arrayOfObjects, prop) {
        const array = arrayOfObjects.map((x) => x[prop])
        if (!arrayOfObjects.length || !array.length) return null

        const evidention = {}
        let maxEl = array[0]
        let max = 1
        for (const item of array) {
            if (evidention[item] == null) {
                evidention[item] = 1
            } else {
                evidention[item]++
            }
            if (evidention[item] > max) {
                maxEl = item
                max = evidention[item]
            }
        }
        return maxEl
    }

    /**
     *
     * @param {object} media
     */
    async update (media) {
        this.loading = true
        this.loading_message = null
        this.updating_in_progress = true
        this.stop_the_process = false
        this.item = media
        try {
            for (const account of this.accounts) {
                if (this.stop_the_process) break
                await this.updateMedia(account)
            }
            if (this.stop_the_process) this.addAbortedStatus()
        } catch (err) {
            this.useOriginalAccount()
            this.validation_error(err)
        }
        this.session.user.account = await get_account_information(this.session)
        this.updating_in_progress = false
        this.stop_the_process = false
        this.screen = 'update-results'
        this.loading_message = null
        this.loading = false
    }

    /**
     *
     */
    addAbortedStatus () {
        for (const account of this.accounts) {
            for (const item of account.items) {
                if (!item.status) {
                    item.status = {
                        level: 'aborted',
                        message: l.t('app.process-aborted', 'Process aborted')
                    }
                }
            }
        }

        return true
    }

    /**
     *
     */
    useOriginalAccount () {
        if (this.accounts.length === 1) return true
        if (this.is_subaccount) {
            this.session = use_subaccount(this.session, this.main)
            return this.session
        }
        this.session = use_main_account(this.session)
        return this.session
    }

    /**
     *
     * @param {object} account
     * @returns
     */
    async updateMedia (account) {
        const {
            items
        } = account
        if (!account.main) {
            this.session = use_subaccount(this.session, account)
        }

        for (const item of items) {
            if (this.stop_the_process) break
            try {
                let uri = `/media/${item.id}`
                this.loading_message = l.t('media-loader.updating-specific-media', 'Updating media with the ID of {} on the account {}', [item.id, account.name])
                const newItem = JSON.parse(JSON.stringify(item))
                if (this.item.origin === 'tts') {
                    newItem.origin = 'tts'
                    newItem.tts_voice = this.item.tts_voice
                    newItem.tts_text = this.item.tts_text
                } else {
                    newItem.origin = 'file'
                    newItem.file = this.item.file
                    newItem.filename = this.item.filename
                }
                if (newItem.extension) {
                    newItem.extension = newItem.extension.id
                    uri = `/extensions/${newItem.extension}${uri}`
                }

                await this.session.replace_item(uri, newItem)
                item.status = {
                    level: 'success',
                    message: l.t('app.successfully-updated', 'Successfully updated')
                }
            } catch (err) {
                const error_msg = err.message ? err.message : l.t('media-loader.loading-error', 'Error loading media for your account with the id of {}', [account.id])
                item.status = {
                    level: 'error',
                    message: error_msg
                }
            }
        }

        this.useOriginalAccount()
        return true
    }

    /**
     *
     */
    exportResultsReport () {
        const data = []
        for (const account of this.accounts) {
            if (account.items) {
                account.items.map((item) => {
                    data.push({
                        account_id: account.id,
                        account_type: !account.main ? l.t('app.subaccount', 'Subaccount') : l.t('app.main-account', 'Main account'),
                        account_name: account.name,
                        media_id: item.id,
                        media_name: item.name,
                        media_type: item.type === 'hold_music' ? l.t('app.music-on-hold', 'Music on hold') : l.t('app.greeting', 'Greeting'),
                        origin: item.origin,
                        extension: item.extension ? `#${item.extension.extension}` : '—',
                        status: item.status ? item.status.level.charAt(0).toUpperCase() + item.status.level.slice(1) : '—',
                        message: item.status ? item.status.message : '—'
                    })

                    return true
                })
            }
        }

        const csv = this.build_csv(data, {}, this.headers)
        Resource.download_csv(
            csv,
            `media-laoder-${this.item.name ? this.item.name : ''}${(new Date()).toLocaleString()}.csv`
        )
        this.csv_downloaded_successfully()
        return true
    }

    /**
     *
     */
    export_media_list () {
        const data = []
        for (const account of this.accounts) {
            if (account.items) {
                account.items.map((item) => {
                    const data_item = {}
                    for (const key of Object.keys(this.headers)) {
                        data_item[key] = this.functions[key](account, item)
                    }
                    data.push(data_item)
                    return true
                })
            }
        }
        const csv = this.build_csv(data, {}, this.headers)
        Resource.download_csv(
            csv,
            `media-list-${(new Date()).toLocaleString()}.csv`
        )
        this.csv_downloaded_successfully()
        return true
    }

    /**
     *
     */
    async import_csv () {
        try {
            if (this.csvMissing()) return null
            this.errors = []
            this.csv_media = this.transformCsvToObjects()
            this.diviseMediaPerAccounts()
            await this.loadMediaItemsPerAccounts()
            this.screen = 'results-of-csv-importing'
        } catch (err) {
            this.validation_error(err)

            return false
        }

        return true
    }

    /**
     *
     */
    back_to_main_form () {
        this.screen = 'accounts-list'
        this.csv_media = null
        this.file = null
    }

    /**
     *
     */
    csvMissing () {
        if (!this.file) {
            this.alert = {
                level: 'error',
                message: l.t('media-list.please-upload-file', 'Please upload .csv file first.')
            }
            this.hide_alert(3)

            return true
        }

        return false
    }

    /**
     *
     */
    transformCsvToObjects () {
        const lines = this.file.split('\n')
        lines[0] = Object.keys(this.headers).join(',')
        const csv_transformer = new CSVTransformer(this, this.file, this.headers)
        const items = csv_transformer.csvToJson(lines.join('\n'))
        for (const item of items) {
            Object.keys(this.headers).map((x) => {
                item[x] = this.importing_functions[x](item[x])
                return true
            })
        }

        return items
    }

    /**
     *
     */
    diviseMediaPerAccounts () {
        for (const account of this.accounts) account.csv_media = []

        for (const item of this.csv_media) {
            if (!item.subaccount) {
                const index = this.accounts.findIndex((x) => x.main && x.selected)
                if (index > -1) {
                    this.accounts[index].csv_media.push(item.media_id)
                } else {
                    item.status = {
                        level: l.t('app.failed', 'Failed'),
                        message: l.t('media-loader.account-not found', '{} not found', [item.subaccount ? l.t('app.subaccount', 'Subaccount') : l.t('app.main-account', 'Main account')])
                    }
                }
            } else {
                const subIndex = this.accounts.findIndex((x) => x.id === item.account_id && x.selected)
                if (subIndex > -1) {
                    this.accounts[subIndex].csv_media.push(item.media_id)
                } else {
                    item.status = {
                        level: l.t('app.failed', 'Failed'),
                        message: l.t('media-loader.account-not found', '{} not found', [item.subaccount ? l.t('app.subaccount', 'Subaccount') : l.t('app.main-account', 'Main account')])
                    }
                }
            }
        }
    }

    /**
     *
     */
    async loadMediaItemsPerAccounts () {
        this.loading = true
        let url = '/media'
        for (const account of this.accounts) {
            if (account.csv_media.length) {
                try {
                    if (!account.main) this.session = use_subaccount(this.session, account)
                    url = `/media?filters[id]=in:${account.csv_media.join(',')}`
                    const allItems = await this.session.get_list_all(url)
                    const { items } = allItems
                    account.items = items
                    const items_ids = items.map((x) => x.id)
                    for (const media of account.csv_media) {
                        const index = this.csv_media.findIndex((x) => x.media_id === media)
                        if (items_ids.includes(media)) {
                            this.csv_media[index].status = {
                                level: l.t('app.success', 'Success'),
                                message: l.t('app.found', 'Found')
                            }
                            this.csv_media[index].is_found = true
                        } else {
                            this.csv_media[index].status = {
                                level: l.t('app.failed', 'Failed'),
                                message: l.t('media-loader.item-not found', 'Item not found')
                            }
                        }
                    }
                } catch (err) {
                    for (const media of account.csv_media) {
                        const index = this.csv_media.findIndex((x) => x.id === media)
                        if (index > -1) {
                            this.csv_media[index].status = {
                                level: l.t('app.failed', 'Failed'),
                                message: l.t('media-loader.error-retreiving-item', 'Error retreiving item.')
                            }
                        }
                    }
                }
            }
        }
        this.useOriginalAccount()
        this.loading = false
    }

    /**
     *
     */
    import_media_from_csv () {
        this.selectAllItems()
        this.screen = 'media-list'
    }

    /**
     *
     */
    reset () {
        this.item = {
            name: '',
            type: 'greeting',
            notes: null,
            origin: 'tts',
            randomized: false,
            tts: {
                voice: 'Joanna / English (US)',
                text: ''
            }
        }
        this.accounts = []
        this.loading_message = null
        this.csv_media = null
        this.clear_filters()
        this.updating_in_progress = false
        this.stop_the_process = false
        this.listAccountsAndSubaccounts()
        this.screen = 'accounts-list'
    }

    /**
     *
     */
    backToCsvForm () {
        this.file = null
        this.loading_message = null
        this.csv_media = null
        this.clear_filters()

        this.screen = 'show-import-csv-form'
    }
}
