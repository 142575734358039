import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Skeleton } from 'foundations-library/components'
import { style as CurrentStyle } from './CurrentBalance'
import { style as PreviousStyle } from './PreviusStatement'
import { style as Payment } from './PaymentMethodCard'
import { style as Plan } from './PlanDetails'

const jss = (t) => {
    const importedStyles = {
        current: (CurrentStyle(t)).container,
        previous: (PreviousStyle(t)).container,
        payment: (Payment(t)).container,
        plan: (Plan(t)).container
    }

    const style = {
        container: {
            display: 'flex',
            '& .loader--column': { width: '50%' }
        }
    }

    for (const key of Object.keys(importedStyles)) {
        const current = importedStyles[key]
        style[key] = {
            height: current.height,
            width: current.width,
            borderRadius: current.borderRadius,
            margin: current.padding
        }
    }

    return style
}

const useStyles = makeStyles(jss)

/**
 *
 */
const Loader = (): JSX.Element => {
    const styles = useStyles()
    return <div className={styles.container} data-testid='billing-overview-loader'>
        <div className='loader--column'>
            <Skeleton variant='rect' className={styles.current}/>
            <Skeleton variant='rect' className={styles.previous}/>
        </div>
        <div className='loader--column'>
            <Skeleton variant='rect' className={styles.payment}/>
            <Skeleton variant='rect' className={styles.plan}/>
        </div>
    </div>
}

export default Loader
