import React, { PropsWithChildren } from 'react'
import { SimpleColorType, VerbalColorType } from '../../../types'
import jss from './style'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(jss)

interface PropsInterface {
    color?: SimpleColorType | [VerbalColorType, VerbalColorType] // 'neutral' || ['primary-900', 'primary-50']
    size?: 'small' | 'medium' | 'large'
    'data-testid'?: string;

}

/**
 *
 */
const Badge = ({
    color = 'neutral',
    size = 'medium',
    children,
    'data-testid': dataTestId = 'badge'
}: PropsWithChildren<PropsInterface>): JSX.Element => {
    const styles = useStyles()
    const classesArr: string[] = [size]
    if (Array.isArray(color) && color.length === 2) {
        classesArr.push(`text-${color[0]}`)
        classesArr.push(`bg-${color[1]}`)
    } else {
        classesArr.push(color)
    }
    const classes = classesArr.filter((x) => x).map((x) => `badge--${x}`).join(' ')
    return <span className={`${styles.badge} ${classes}`} data-testid={dataTestId}>
        {children}
    </span>
}

export default Badge
