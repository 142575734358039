import React from 'react'
import ColumnType from './Column.interface'
import { makeStyles } from '@material-ui/core'

const style = (t) => {
    const { typography, colors } = t['foundations-library']
    return {
        tableBody: {
            '& tr': {
                boxShadow: '0px -1px 0px 0px rgba(133, 146, 154, 0.2) inset',
                height: '62px',
                '& td': {
                    ...typography.body3,
                    color: colors.neutral[900],
                    paddingRight: '20px'
                }
            }
        }
    }
}

const useStyles = makeStyles(style)
interface PropsInterface {
    columns: ColumnType[];
    data: Record<string, unknown>[];
    'data-testid'?: string;
}

/**
 *
 */
const TableBody = ({
    data,
    columns,
    'data-testid': dataTestId = 'table-body'
}: PropsInterface): JSX.Element => {
    const styles = useStyles()
    return (
        <tbody className={styles.tableBody} data-testid={dataTestId}>
            {data.map((item, i) => {
                return (
                    <tr key={`table-row-${i}`} data-testid={`${dataTestId}-row-${i}`}>
                        {columns.map(({ accessor }, j) => {
                            return <td key={accessor} data-testid={`${dataTestId}-row-${i}-data-${j}`}>{item[accessor] ? item[accessor] : <>&mdash;</>}</td>
                        })}
                    </tr>
                )
            })}
        </tbody>
    )
}

export default TableBody
