import React from 'react'
import Button from 'button'
import { makeStyles } from '@material-ui/core/styles'
import { backBtnArrow, backBtnArrowHover } from 'pdc-icons'
import { ChevronBackIcon } from 'svg-icons'

const useStyles = makeStyles(theme => ({
    backButtonWrapper: {
        '&:hover': {
            '& .back-btn': {
                display: 'none'
            }
        },
        '&:not(:hover)': {
            '& .back-btn-hover': {
                display: 'none'
            }
        }
    },
    backButtonVerbal: {
        fontSize: '11px',
        lineHeight: '12px',
        '& > .MuiSvgIcon-root': {
            height: '16px',
            width: '16px'
        }
    }
}))

interface PropsInterface {
    onClick(): any;
    verbal: boolean;
    children: string;
    className: string;
}

/**
 *
 */
export default function BackButton (props: PropsInterface): JSX.Element {
    const classes = useStyles()
    if (!props.verbal) {
        return (
            <span className={classes.backButtonWrapper} onClick={props.onClick}>
                <img className='back-btn' src={backBtnArrow} alt='Back' />
                <img className='back-btn-hover' src={backBtnArrowHover} alt='Back' />
            </span>
        )
    }

    return (
        <Button variant="text" {...props} className={`${classes.backButtonVerbal} ${props.className}`}>
            <ChevronBackIcon height={16} width={16}/>
            {props.children || 'back'}
        </Button>
    )
}
