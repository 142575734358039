
interface StyleProps {
    isOpen: boolean
    selectedValue: any
    selectedItemIcon: string
    disabled: boolean
}

/**
 *
 */
const style = (t) => {
    const { colors } = t['foundations-library']
    return {
        container: {
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            '& .select-menu': {
                position: 'absolute',
                top: 'calc(100% + 2px)',
                left: 0
            }
        },
        select: {
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            padding: (props: StyleProps) => (props.selectedItemIcon ? '0 0 0 30px' : ''),
            fontSize: '14px',
            color: (props: StyleProps) =>
                props.disabled ? colors.neutral[300] : props.selectedValue ? colors.neutral[900] : !props.isOpen ? colors.neutral[500] : colors.neutral[700],
            cursor: props => (props.disabled ? 'unset' : 'pointer'),
            pointerEvents: props => (props.disabled ? 'none' : 'auto')
        },
        icon: {
            position: 'absolute',
            display: 'flex',
            alignItems: 'center'
        },
        chevron: {
            position: 'absolute',
            right: '0px',
            top: '50%',
            transform: 'translateY(-50%)',
            pointerEvents: 'all',
            cursor: (props: StyleProps) => (props.disabled ? 'unset' : 'pointer')
        }
    }
}

export default style
