import { getVerbalColors } from 'helpers'

/**
 *
 */
const style = (t) => {
    const verbalColors = getVerbalColors(t['foundations-library'].colors)
    const coloring = {}
    for (const c of Object.keys(verbalColors)) {
        coloring[`&.divider--${c}`] = {
            borderColor: verbalColors[c]
        }
    }
    return {
        divider: {
            '&.divider--horizontal': {
                borderTop: '1px solid white',
                marginTop: '20px',
                marginBottom: '20px'
            },
            '&.divider--vertical': {
                borderLeft: '1px solid white',
                marginLeft: '10px',
                marginRight: '10px',
                display: 'inline'
            },
            ...coloring
        }
    }
}

export default style
