/**
 *
 */
const style = () => ({
    table: {
        minWidth: '75%',
        maxWidth: '100%'
    }
})

export default style
