import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import style from './style'
import Icon from '../Icon/Icon'
import Label from '../Label/Label'
import VerbalColorType from '../../helpers/types/VerbalColor.interface'
import CommonInputType from '../../helpers/types/CommonInput.interface'

const useStyles = makeStyles(style)

interface PropsInterface extends React.ComponentProps<'radio'>, CommonInputType {
    label?: string;
    color?: VerbalColorType;
    iconRight?: boolean;
}

/**
 *
 */
const Radio = ({
    disabled,
    label,
    children,
    color = 'primary',
    iconRight = false,
    onChange,
    checked = false,
    className,
    'data-testid': dataTestId = 'radio-button'
}: PropsInterface): JSX.Element => {
    const styles = useStyles()
    const [iconProps, setIconProps] = useState({
        color,
        name: 'radio-button-unchecked'
    })

    const setProperColors = () => {
        const iconName = checked ? 'radio-button-checked' : 'radio-button-unchecked'
        if (disabled) {
            setIconProps({
                name: iconName,
                color: 'neutral-300'
            })
        } else {
            setIconProps({
                name: iconName,
                color
            })
        }
    }
    useEffect(setProperColors, [])
    useEffect(setProperColors, [checked])

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!disabled && onChange) onChange(e)
    }

    const iconClasses = [
        'icon',
        iconRight ? 'icon-right' : 'icon-left'
    ].filter((x) => x).map((x) => `radio--${x}`).join(' ')

    return (
        <div className={`${styles.radio} ${className || ''}`} data-testid={`${dataTestId}-common-input`}>
            <Label disabled={disabled} data-testid={`${dataTestId}-label`}>
                <input type="radio" checked={ checked } onChange={handleOnChange} disabled={disabled} data-testid={dataTestId}/>
                {iconRight && (label || children)}
                <Icon className={iconClasses} name={iconProps.name} size={24} color={iconProps.color}/>
                { !iconRight && (label || children) }
            </Label>
        </div>
    )
}

/**
 *
 */
export type RadioType = PropsInterface

export default Radio
