import React from 'react'

import style from './style'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '../Typography/Typography'
import VerbalColorType from '../../helpers/types/VerbalColor.interface'

const useStyles = makeStyles(style)

interface PropsInterface extends React.ComponentProps<'label'> {
    disabled?: boolean;
    color?: VerbalColorType;
    'data-testid'?: string;
}

/**
 *
 */
const Label = ({
    children,
    className = '',
    disabled = false,
    'data-testid': dataTestId = 'label',
    ...props
}: PropsInterface): JSX.Element => {
    const styles = useStyles()

    return (
        <Typography
            variant="body2"
            tag="label"
            className={`${className} ${styles.label}`}
            color={disabled ? 'neutral-300' : props.color}
            data-testid={dataTestId}
        >{ children }</Typography>
    )
}

export default Label
