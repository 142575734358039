import { getVerbalColors } from 'helpers'

const boxShadow = (color, important = false, borderSize = '3px') => ({ boxSizing: 'border-box', boxShadow: `inset 0 0 1px ${borderSize} ${color}${important ? ' !important' : ''}` })

/**
 *
 */
const style = (t) => {
    const { colors } = t['foundations-library']
    const verbalColors = getVerbalColors(colors)

    const coloring = {
        '&.checkbox--disabled': {
            ...boxShadow(colors.neutral[500], true),
            '&.checkbox--active': {
                backgroundColor: `${colors.neutral[500]} !important`,
                boxShadow: 'none'
            }
        }
    }
    for (const [verbal, color] of Object.entries(verbalColors)) {
        coloring[`&.checkbox--active.checkbox--${verbal}`] = {
            backgroundColor: color,
            boxShadow: 'none'
        }
    }

    return {
        checkbox: {
            height: '24px',
            '& label': {
                cursor: 'pointer'
            },
            '& .checkbox--icon-wrapper': {
                cursor: 'pointer',
                display: 'inline-flex',
                alignItems: 'center',
                ...boxShadow(colors.neutral[500]),
                position: 'relative',
                borderRadius: '2px',
                width: '18px',
                minWidth: '18px',
                maxWidth: '18px',
                height: '18px',
                ...coloring,
                '&.checkbox--icon-right': {
                    marginLeft: '8px'
                },
                '&.checkbox--icon-left': {
                    marginRight: '8px'
                },
                '& .checkbox--icon': {
                    margin: '0 auto'
                }
            },
            '& input[type="checkbox"]': {
                display: 'none'
            }
        }
    }
}

export default style
