/**
 *
 */
const style = (t) => {
    const { colors, typography } = t['foundations-library']
    const coloring = {}
    for (const c of Object.keys(colors)) {
        const key = `&.infotip--${c}`
        coloring[key] = {
            backgroundColor: colors[c][600]
        }
    }
    return {
        infotip: {
            position: 'relative',
            display: 'inline-block',
            cursor: 'pointer',
            '& .infotip': {
                ...typography.body3,
                visibility: 'hidden',
                borderRadius: '6px',
                padding: '20px',
                color: '#fff',
                width: '100%',
                textAlign: 'center',
                position: 'absolute',
                zIndex: 1,
                minWidth: '200px',
                '&.infotip--right': {
                    top: '-5px',
                    left: '105%'
                },
                '&.infotip--left': {
                    top: '-5px',
                    right: '105%'
                },
                '&.infotip--top': {
                    bottom: '100%',
                    left: '50%',
                    marginLeft: '-50%'
                },
                '&.infotip--bottom': {
                    top: '100%',
                    left: '50%',
                    marginLeft: '-50%'
                },
                ...coloring
            },
            '&:hover .infotip': {
                visibility: 'visible'
            }
        }
    }
}

export default style
