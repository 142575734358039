<template>
    <div data-test-id="extension-selector-container">
        <w-alert v-if="alert" :message="alert.message" :level="alert.level"/>
        <w-loader v-if="loading" :message="false" :no_margins="true"/>
        <template v-else>
          <APIAutocomplete
            v-model="extension"
            :before_list="before_list"
            mode="extensions"
            :return_object="true"
            :placeholder="placeholder"
            :no_update="no_update"
            :options_list="options_list && options_list.includes('remove') && !before_list.find((x) => !x.value) ? ['remove'] : []"
            @click:remove="$emit('click:remove')"
            :disabled="!!disabled"
            :additional_options="additional_options"
            :filtered_values="filtered_values"
            :key="!!disabled"
            class="extension-selector"
            data-test-id="extension-selector"
          />
          <FormModal
            v-model="show_notification_modal"
            @close="show_notification_modal = false"
            max-width="500"
            data-test-id="extension-selector-notification-modal"
          >
          <w-form @submit.prevent="save">
            <w-loader v-if="ext_loading"/>
            <div v-else-if="extension">
                <div class="mb-3">
                  <div class="font-weight-bold">
                    {{ l.t("extension.notification-label-sms", "Phone number for SMS forwarding and Voicemail notifications") }}
                  </div>
                  <Tel
                      :value="sms"
                      @changed="sms = $event.clean_number"
                      :required="!emails.length"
                      class="w-100"
                      data-test-id="extension-selector-notification-tel-input"
                  />
                </div>
                <div :key="emails.length" class="mt-5">
                  <div class="font-weight-bold">
                    {{ l.t("extension.notification-label", "Emails for SMS, voicemail and fax notifications") }}
                  </div>
                  <Email
                    v-for="(email, i) in emails"
                    v-model="emails[i]"
                    :rules="[$validation.required()]"
                    :key="i"
                    :closable="true"
                    @closed="$delete(emails, i)"
                    :class="{'mb-2': i < emails.length -1}"
                    class="w-100"
                    data-test-id="extension-selector-notification-email-input"
                  />
                  <w-btn
                    @click="emails.push('')"
                    class="mt-3 pl-0"
                    color="primary"
                    text
                    changes-form
                    min-width="100px"
                    data-test-id="extension-selector-notification-add-email"
                  >
                      <v-icon small>$vuetify.icons.add</v-icon>{{ l.t("app.email-add", "Add email address") }}
                  </w-btn>
                </div>
            </div>
            <div class="default-modal-buttons">
              <w-btn class="mr-5" color="secondary" @click="show_notification_modal = false">{{l.t('app.cancel', 'Cancel')}}</w-btn>
              <w-btn color="primary" type="submit">{{l.t('app.save-notifications-settings', 'Save notifications settings')}}</w-btn>
            </div>
          </w-form>
          </FormModal>
          <FormModal
              v-model="show_call_flow_modal"
              :with_x="true"
              @close="show_call_flow_modal = false"
              width="1000"
              :key="modal_key"
            >
              <w-loader v-if="ext_loading"/>
              <div v-else-if="extension">
                <RouteShow
                  v-if="extension && extension.route && extension.route.id"
                  :id="extension.route.id"
                  :disable_switcher="true"
                  @click:cancel="show_call_flow_modal = false"
                  @updated="update_screen"
                  :key="extension.id"
                  data-test-id="extension-selector-show-route-modal"
                />
                <RouteCreate  v-else
                :extension="extension"
                @click:cancel="show_call_flow_modal = false"
                @created="created_route"
                @updated="on_route_update"
                />
              </div>
          </FormModal>
        </template>
    </div>
</template>

<script>
  import Cachier from 'cachier';
  import {vueComponent} from 'helpers';
  import l from '../../libs/lang';
  import Extension from '../../models/Extension';
  import Autocomplete from '../../models/Autocomplete';
  import Tel from '../elements/Tel.vue';
  import Email from '../elements/Email.vue';
  import AddToListButton from '../elements/AddToListButton.vue';
  import FormModal from '../elements/modal/FormModal.vue';
  import APIAutocomplete from '../elements/form/APIAutocomplete.vue';

  export default {
    name: 'ExtensionSelector',
    props: {
      value: {
        retuired: false,
      },
      preselect_default: {
        type: Boolean,
        default: false,
      },
      options_list: {
        type: Array,
        default: () => [],
        validator: (v) => v.every((x) => ['notifications', 'call-flow-link', 'remove'].includes(x)),
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      placeholder: {
        type: String,
      },
      no_update: {
        type: Boolean,
        default: false,
      },
      full_width: {
        type: Boolean,
        default: false,
      },
      null_text: {
        type: String,
      },
      filtered_values: {
           type: Array,
      },
      return_object: {
        type: Boolean,
        default: false,
      },
    },
    components: {
      Email,
      Tel,
      FormModal,
      AddToListButton,
      APIAutocomplete,
      RouteShow: () => import('../screens/routes/show.vue'),
      RouteCreate: () => import('../screens/routes/create.vue'),
    },
  data() {
      return {
        l,
        alert: null,
        loading: false,
        ext_loading: false,
        modal_key: Date.now(),
        extension: this.$props.value ? this.$props.value : null,
        extModel: new Extension(this.$session, vueComponent(this)),
        emails:
          this.$props.value
          && this.$props.value['voicemail']
          && this.$props.value['voicemail']['notifications']
          && this.$props.value['voicemail']['notifications']['emails']
            ? this.$props.value['voicemail']['notifications']['emails']
            : [],
        sms:
          this.$props.value
          && this.$props.value['voicemail']
          && this.$props.value['voicemail']['notifications']
            ? this.$props.value['voicemail']['notifications']['sms']
            : '',
        show_notifications: false,
        cachier: new Cachier(this.$session.user.id),
        additional_options: [],
        show_notification_modal: false,
        show_call_flow_modal: false,
      };
    },
    async created() {
      this.$data.loading = true;
      let defaultExtension;
      if (this.$props.preselect_default && !this.$props.value) {
        const cacheKey = 'default_extension';
        const cachedExtension = this.$data.cachier.getItem(cacheKey);
        if (cachedExtension !== null) {
          defaultExtension = cachedExtension;
        } else if (this.$session.user.account && this.$session.user.account.default_voip_phone_id) {
          defaultExtension = {id: this.$session.user.account.default_voip_phone_id};
        } else {
          const firstExtension = await this.$session.get_list('/extensions', 1);
          if (firstExtension.items.length) {
            defaultExtension = firstExtension.items[0];
          } else {
            const rd = () => `${Math.floor(Math.random() * 10)}`;
            const data = {
              name: 'Default extension',
              voicemail: {
                password: `${rd()}${rd()}${rd()}${rd()}${rd()}${rd()}`,
              },
            };
            const createdExtension = await this.$session.create_item('/extensions', data);
            defaultExtension = createdExtension;
          }
        }
        if (defaultExtension) {
          this.$data.extension = defaultExtension;
        }
        this.cachier.setItem(cacheKey, defaultExtension);
      }
      if (this.extension && typeof this.extension === 'number') {
        this.$data.extension = {id: this.$data.extension}
      }
      this.add_options();
      await this.loadFullExtensionIfNeeded();
      this.$data.loading = false;
    },
    methods: {
      created_route(val) {
        const params = {name: val.name, id: val.id};
        this.on_route_update(params);
      },
      async on_route_update(data) {
        this.$data.extension.loading = true;
        try {
          if (!this.$data.extension.route || (this.$data.extension.route && this.$data.extension.route.id !== data.id)) {
            this.$data.extension.route = await this.$data.extModel.update_route(data);
          }
          this.$data.extModel.successfulUpdate();
        } catch (err) {
          this.$data.extModel.validation_error(err);
        }
        this.$data.extension.loading = false;
      },
      update_screen(data) {
        if (data) {
          this.extension.route = data;
          const ac = new Autocomplete(
            'routes',
            this.$session,
            null,
            {
                value: this.$data.extension.route.id,
            },
            vueComponent(this),
          );
          ac.mode.model.updateCache(data, ac.mode_name);
        }
        setTimeout(() => {
          this.$data.show_call_flow_modal = false;
        }, 1000);
      },
      add_options() {
        this.$data.additional_options = [];
        if (!this.$data.extension) return null

        if (this.$data.extension && this.$props.options_list && Array.isArray(this.$props.options_list)) {
          if (this.$props.options_list.includes('notifications')) {
            this.$data.additional_options.push({
              title: l.t('app.notifications', 'Notifications'),
              icon: '$vuetify.icons.alert',
              cb: () => this.$data.show_notification_modal = true,
            });
          }
          if (this.$data.extension.id && this.$props.options_list && this.$props.options_list.includes('call-flow-link')) {
            this.$data.additional_options.push({
              title: l.t('app.show-call-flow', 'Show call flow'),
              icon: '$vuetify.icons.gear',
              cb: () => this.$data.show_call_flow_modal = true,
            });
          }
        }
      },
      async save(e) {
        if (e) e.preventDefault();
        const cache_key = 'extensions';
        this.cachier.removeItem(cache_key);
        this.$data.loading = true;
        try {
          if (!this.$data.extension['voicemail']) {
            this.$data.extension['voicemail'] = {};
          }
          if (!this.$data.extension['voicemail']['notifications']) {
            this.$data.extension['voicemail']['notifications'] = {};
          }
          this.$data.extension['voicemail']['notifications'][
            'emails'
            ] = this.$data.emails;
          this.$data.extension['voicemail']['notifications']['sms'] = this.$data.sms || null;
          await this.$session.replace_item(`/extensions/${this.$data.extension['id']}`, this.$data.extension);
          this.$data.show_notification_modal = false;
          } catch (err) {
            this.$data.alert = {
                message: l.t('app.generic-error', 'Something went wrong'),
                level: 'error'
            }
            setTimeout(() => this.$data.alert = null, 10000)
            window.scrollTo(0, 0)
          }
          this.$data.loading = false;
      },
      loadFromCache() {
        if (this.$data.extension) {
          const ac = new Autocomplete(
            'extensions',
            this.$session,
          );
          return ac.get_from_cache(this.$data.extension.id);
        }
        return null;
      },
      async loadFullExtensionIfNeeded() {
        if (
          this.$data.extension
          && typeof this.$data.extension === 'object'
          && (
            this.$props.options_list
            && this.$props.options_list.includes('call-flow-link')
            || this.$props.options_list.includes('notifications')
          )
          && !Object.prototype.hasOwnProperty.call(this.$data.extension, 'voicemail')
          && this.$data.extension.id
        ) { // brief representation for devices lines ex, chr rules etc.
          this.$data.ext_loading = true;
          try {

            this.$data.extension = this.loadFromCache() || await this.$session.get_item(`/extensions/${this.$data.extension.id}`);
          } catch (err) {
            console.log('Error while loading full extension', err);
          }
          this.$data.ext_loading = false;
        }
      },
    },
    watch: {
      async show_notification_modal(v) {
        this.$data.modal_key = Date.now();
      },
      async show_call_flow_modal(v) {
        this.$data.modal_key = Date.now();
      },
      extension(value) {
        this.$data.extModel.item = value;
        this.$data.emails = value
          && value['voicemail']
          && value['voicemail']['notifications']
          && value['voicemail']['notifications']['emails']
            ? value['voicemail']['notifications']['emails']
            : [];
        this.$data.sms = value
          && value['voicemail']
          && value['voicemail']['notifications']
          && value['voicemail']['notifications']['sms']
            ? value['voicemail']['notifications']['sms']
            : '';
        if (value && this.$props.preselect_default) {
          const cache_key = 'default_extension';
          this.cachier.setItem(cache_key, value);
        }
        this.add_options();
        const val = this.$props.return_object ? value : value?.id;
        this.$emit('input', val);
      },
    },
    computed: {
      before_list() {
        if (!this.$props.preselect_default && !this.$props.placeholder) {
          return [{ value: null, text: this.$props.null_text || l.t('extensions.all-extensions', 'All extensions') }]
        }
        return [];
      }
    }
  };
</script>
